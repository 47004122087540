.trial-banner {
  background-color: $background-teal;
  font-size: 1.1rem;
  font-weight: bold;
  padding: 1.7rem 1rem;
  text-align: center;
}

.trial-banner-warning {
  color: $white;
}

.trial-banner-danger {
  color: $white;
}

.trial-banner-hyperlink {
  color: inherit;
  text-decoration: underline;
}

.trial-banner-hyperlink:hover {
  color: blue;
  text-decoration: underline;
}
