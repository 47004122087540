/*
	HYPERION _COMP
*/

.attendance-report-subcontractor-section {
  font-size: 16px;
  margin: 15px 0 30px;

  &.in-subcontractor {
    margin-bottom: 10px;
  }
  .subcontractor-auto-complete {
    border: 2px solid $background-gray-darkest;
  }
}

.pl-project-actions {
  .p-chkbox-label {
    font-weight: 600;
  }
}

.attendance-list-table-holder {
  max-height: 600px;
  overflow-y: auto;
}

.hyperion-dnd-container {
  border: none;
  background: inherit;
  background-color: inherit;
  margin: 0;
  padding: 10px 0 10px 20px;
  &:nth-child(odd) {
    background-color: inherit;
  }
  &.empty-container {
  }
  &.gu-over,
  &.gu-over.gu-empty {
    // border: 2px dashed darkslategray;
    background-color: $brand-primary-color;
  }
  &.gu-empty {
    // border: 2px dashed darkslategray;
    border: none;
    &.gu-over {
      padding-top: 30px;
    }
  }
}

.ngx-dnd-item {
  padding: 0 !important;
  margin: 0 !important;
  background: inherit !important;
  background-color: inherit !important;
  border: none !important;
  border-width: 0 !important;
  border-style: none !important;
  border-color: transparent !important;
  &:nth-child(odd) {
    background-color: none;
  }
  &:hover {
    border: none !important;
  }
  &.gu-transit {
    // when dragging
    opacity: 0.5;
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=50)';
    filter: alpha(opacity=50);
  }
  .subcontractor-drag {
    display: flex;
    width: 100%;
    padding: 5px;
    .drag-handle {
      margin: 10px 10px 10px 0;
    }
    hyperion-card {
      flex-grow: 1;
    }
    .contractor-header {
      display: flex;
      justify-content: space-between;
      > h4 {
        margin: 0;
      }
      .contractor-actions {
        .delete-project-button {
          margin-right: 25px;
        }
        .show-toggle {
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
  }
}

.subcontractor-auto-complete {
  position: relative;
  .add-subcontractor {
    position: absolute;
    right: 19px;
    top: 37px;
    z-index: 2000;
    button {
      z-index: 2000;
    }
  }
}

.subcontractor-mail-dialog {
  p,
  div {
    margin-bottom: 5px;
  }
  div {
    width: 100%;
    input {
      width: 100%;
    }
  }
}

.collection-list {
  .user-ids {
    .p-multiselect {
      .p-multiselect-trigger {
        .fa {
          margin-top: 0.8em;
        }
      }
    }
  }
}

.attendance-report-list-table {
  tbody {
    tr {
      td {
        vertical-align: top;
        &.is-canceled {
          text-decoration: line-through;
        }
        .fa.delete {
          margin-left: 4px;
        }

        .edits-history {
          .edits-history-item {
            font-style: italic;
            &:first-child {
              font-style: normal;
            }
          }
        }
      }
    }
  }
}

/*
    Settings component
*/

/*
    Day
*/

.create-day-holder {
  h2 {
    font-weight: bold;
  }
  small {
    font-weight: 400;
    font-size: 70%;
    color: $btn-danger-color;
    transition: color 0.5s;
    &:hover {
      cursor: pointer;
      color: $brand-primary-color;
    }
  }
}

.sub-title-row {
  position: relative;
  padding: 0 0.5em;
  h4 {
    display: inline-block;
  }
  .button-group {
    display: inline-block;
    float: right;
    font-size: 14px;
    button {
      &.active {
        background-color: $primary-btn-color;
        color: white;
      }
    }
    padding-bottom: 8px;
    .delete {
      color: $btn-danger-color;
      background: none;
      border: none;
    }
  }
  .export-buttons {
    display: inline-block;
    font-size: 14px;
    margin: 0 auto;
    @media (min-width: $break-laptop) {
      position: absolute;
      left: 0;
      right: 0;
      width: 600px;
    }
  }
}

.day-right-section {
  table {
    border: 1px solid #d8d6d6;
    color: $text-color;
    th {
      color: $text-color;
      background-color: $background-gray;
    }
  }
}

// Day advanced
.advanced-body-section {
  div {
    &.has-error {
      border: 1px $btn-danger-color solid;
      .fa-exclamation-triangle {
        color: $btn-danger-color;
      }
    }
  }

  .td-print-toggle {
    @media print {
      display: none;
    }
  }
  .td-print-toggle-on {
    display: none;
    @media print {
      display: table-cell;
    }
  }

  table {
    width: 100%;
    font-size: 13px;
    @media print {
      width: 100%;
      font-size: 8px;
      .inner-table {
        td {
          input {
            font-size: 8px;
          }
        }
      }
    }

    thead {
      display: table;
      table-layout: fixed;
    }
    tbody {
      display: block;
      width: 100%;
    }

    &.holder-table {
      border: 1px solid #dadada;
      tr {
        &:nth-child(even) {
          background-color: #f9f9f9;
        }
      }
    }

    tr {
      display: table;
      width: 100%;
      table-layout: fixed;
      background-color: #fcfcfc;
    }
    th,
    td {
      padding: 7px;
      border: 1px solid #dadada;
      border-bottom: none;
      &:first-child {
        border-left: none;
      }
      &:last-child {
        border-right: none;
      }
    }
    th {
      text-align: left;
      font-weight: bold;
      border-top: none;
      background-color: #f9f9f9;
      .weekend {
        color: $btn-danger-color;
      }
    }
    div > tbody {
      height: auto;
      overflow: hidden;
    }
    .inner-day {
      margin-bottom: 4px;
      &:last-child {
        margin: 0;
      }
    }
    .inner-table {
      width: 100%;
      margin-bottom: 6px;
      tbody {
        display: table-row-group;
      }
      td,
      tr {
        padding: 0;
        border: none;
      }
      tr {
        display: table-row;
        background-color: none !important;
        background: none !important;
        &.notarize-tr {
          margin-top: 2px;
          border-top: 1px solid $background-gray-darkest;
        }
      }
      td {
        text-align: left;
        vertical-align: bottom;
        padding: 1px;
        input {
          font-size: 12px;
          padding: 1px;
          display: inline;
          width: 30px;
          border: 1px solid $background-gray-darkest;
        }
        &.align-center {
          text-align: center;
        }
      }
      .subsistence-cell {
        white-space: nowrap;
        text-overflow: clip;
        overflow: hidden;
        max-width: 1px;
        font-size: 10px;
      }
    }
  }
}

/*
    Home suppages
*/

.extra-functions {
  h4 {
    padding-bottom: 4px;
    border-bottom: solid 1px #cccece;
  }
}

.benefits {
  .text {
    width: 310px;
    text-align: left;
    vertical-align: top;
    padding-right: 15px;
  }

  img {
    max-width: 100%;
  }
}

/*
   ------------ Home Start
*/

$scrollbar-width: 10px;
$small-height: 100px;
$medium-height: 150px;
$large-height: 200px;

.card-holder {
  height: calc(#{$large-height} + 27px);
  overflow-y: scroll;
  overflow-x: hidden;
  margin-bottom: 0.5em;
  &.holder-small {
    height: calc(#{$small-height} + 27px);
  }
  &.holder-medium {
    height: calc(#{$medium-height} + 27px);
  }
}

span.erase {
  font-size: 1.2em;
  line-height: 100%;
  color: $btn-danger-color;
  &:hover {
    cursor: pointer;
  }
}

div.erase {
  float: right;
  padding: 0 1em;
}

.home-start-header-label {
  display: block !important;
  @media (max-width: $break-laptop) {
    display: none !important;
  }
}

.home-start-responsive-label {
  display: none !important;
  @media (max-width: $break-laptop) {
    display: block !important;
  }
}

.home-start-object {
  position: relative !important;
  border-bottom: 1px solid #c2c2c2;
  @media (min-width: $break-laptop) {
    border: none;
  }
  p,
  a {
    line-height: 100%;
  }
  &:hover {
    background-color: #efefef;
  }
}

.start-smart-scroll {
  .scroll-body {
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100%;
    padding-bottom: 2em;
    margin-bottom: 0.5em;
    &.small {
      height: $small-height;
      @media (max-width: 1025px) {
        height: calc(#{$small-height} + 150px);
      }
    }
    &.medium {
      height: $medium-height;
      // @media (max-width: 1025px){
      //     height: calc(#{$medium-height} + 150px);
      // }
    }
    &.large {
      height: $large-height;
      // @media (max-width: 1025px){
      //     height: calc(#{$large-height} + 150px);
      // }
    }
  }
  .home-start-header-label {
    width: calc(100% - #{$scrollbar-width});
    padding-bottom: 0.2em;
  }
}

/*
    CompanyNoticeBoard
*/

.msg {
  // margin-bottom: 1.3em;
  .msg-header {
    margin-bottom: 0.8em;
    font-style: italic;
    width: 100%;
    display: block;
    .erase-holder {
      float: right;
      padding: 0 0.5em;
    }
    .msg-header-holder {
      width: 85%;
      float: left;
      margin-bottom: 0.8em;
    }
    .msg-title {
      font-style: normal;
      font-weight: 700;
      padding-right: 0.2em;
      @include fixLongText(25em);
      @media (max-width: 29em) {
        max-width: 90%;
      }
    }
    .msg-user {
      padding-right: 0.2em;
      @include fixLongText(15em);
    }
  }
  .msg-text {
    clear: both;
  }
}

/*
    Hotlinks
*/
.hotlinks {
  margin-bottom: 0.5em;
}

/*
    rssFeedsNews
*/
.rss-single {
  padding: 1em 0.5em;
  border-bottom: $light-gray 1px solid;
  div {
    &:first-child {
      margin-bottom: 0.7em;
    }
  }
}

/*
    Mails
*/
.start-mail {
  td {
    &:hover {
      cursor: pointer;
    }
  }
}

/*
    Picture feed
*/
.picture-feed {
  padding: 1em 0;
  .picture-feed-pic {
    padding: 1em 0;
    text-align: center;
    border-bottom: 1px solid $background-gray-darker;
    margin-bottom: 1em;
    font-size: 0.9em;
    img {
      max-width: 100%;
      margin-bottom: 1em;
    }
  }
}

/*
    Image Gallery
*/

.image-gallery-single {
  display: table-cell;
}

.gallery-image-actions {
  padding: 10px;
  padding-right: 20px;
  .input-handle {
    font-size: 14px;
    label {
      display: block;
      margin-bottom: 5px;
      color: darkgray;
    }
    textarea {
      border: none;
      background-color: $background-gray-darker;
      resize: none;
    }
  }
}

.rotate-box {
  font-size: 18px;
  color: gray;
  span:first-child {
    margin-right: 5px;
  }
}

.outerContainer {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.95);
  font-family: sans-serif;
  z-index: 70;
  .comment {
    position: absolute;
    width: 90%;
    bottom: 2vh;
    left: 5%;
    right: 5%;
    text-align: center;
    display: none;
    z-index: 200;
    &.active {
      display: block;
    }

    h4 {
      color: white;
      -webkit-text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9);
      -moz-text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9);
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9);
      background: rgba(0, 0, 0, 0.25);
      padding: 5px;
      display: inline-block;
    }
  }
}

.imageContainer {
  position: absolute;
  float: none;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.imageContainer .image,
.imageContainer .preloading-image {
  visibility: hidden;
}

.imageContainer .image.active {
  position: absolute;
  visibility: visible;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin: auto auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  opacity: 1;
}

.arrow {
  opacity: 0;
}

.arrow:hover {
  cursor: pointer;
}

.outerContainer:hover .arrow.activeArrow {
  height: calc(20px + 1.5vw);
  position: absolute;
  /* use sass variables here once they are ready */
  top: calc(50% - (20px + 1.5vw) / 2);
  bottom: 50%;
  z-index: 1;
  opacity: 1;
  transition: all ease-out 0.5s;
}

.arrow.left {
  left: 2vw;
}

.arrow.right {
  right: 2vw;
}

.arrow:not(.activeArrow):hover {
  opacity: 0;
  cursor: pointer;
  transition: all ease-out 0.5s;
}

md-button-toggle-group {
  display: table;
  top: 70px;
  margin: 0 auto;
  background-color: #fff;
  z-index: 500;
}

md-button-toggle.checked {
  background-color: #a0a0a0;
}

[md-fab],
[md-mini-fab] {
  background-color: #aaaaaa;
}

/*
    Upcomming activities
*/
.start-table {
  th,
  td {
    padding: 0.5em;
  }
}

/*
    Table
*/
.table-scroll {
  word-wrap: break-word;
  &.small {
    tbody {
      height: $small-height;
    }
  }
  &.medium {
    tbody {
      height: $medium-height;
    }
  }
  &.large {
    tbody {
      height: $large-height;
    }
  }
  tbody {
    display: block;
    margin-bottom: 0.5em;
    overflow-y: scroll;
  }
  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
  th {
    padding: 0.7em 0.5em;
    text-align: left;
    line-height: 100%;
  }
  td {
    padding: 0.5em;
  }
  thead {
    width: calc(100% - #{$scrollbar-width});
  }
  table {
    width: 100%;
  }
}

/*
    Home Start Second Section
*/

/*
    Login
*/
.fake-body {
  z-index: 700;
  padding-top: 40px;
  padding-bottom: 40px;
  /*background-color: #fff !important;*/
  height: 100vh;
  margin: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-image: var(--login-background);
  background-color: #fafafa;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-container {
  width: 470px;
  max-width: 95%;
  @media (min-width: $break-laptop) {
    margin-top: -65px;
  }
}

.login-card {
  position: relative;
  background-color: white;
  /*border-radius: 0.188em;*/
  //   border: 1px solid #b7b7b7;
  /*margin-top: 4em;*/
  @include box-shadow(0px 0px 30px 0px rgba(0, 0, 0, 0.7));
}

.login-image {
  padding: 3em 6em 2em 6em;
  text-align: center;
  max-width: 100%;
}

.login-message {
  margin-top: 1em;
  font-size: 0.85em;
  padding: 0.5em;
  margin: 0 0.5em;
  border: dashed 1px var(--brand-primary-color);
}

/*
    Offer
*/

.p-dropdown-item,
.p-dropdown-label {
  display: flex !important;
  align-items: flex-end !important;
}
.table-dropdown,
.p-dropdown-items {
  .dot {
    height: 1rem;
    width: 1rem;
    background-color: #bbb;
    border-radius: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 0.5rem;
    display: none;

    &.warning {
      background-color: $btn-warning-color;
      display: block;
    }
    &.success {
      background-color: $success-btn-color;
      display: block;
    }
    &.danger {
      background-color: $btn-danger-color;
      display: block;
    }
  }
}

.offer-form {
  .form-control-label {
    font-weight: 600 !important;
  }
}

.rot-label {
  border-left: #1c84c6 2px solid;
  padding-left: 5px;
}

/*
    Project
*/

/* --- Calendar --- */
// project-calendar-gantt {
.project-gantt-grid {
  @media print {
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    transform: rotate(-90deg) translateX(-1100px);
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
    zoom: 0.7;
  }
  #project-calendar-gantt {
    project-calendar-gantt {
      display: block;
      height: 600px;
      max-height: 70vh;
      position: relative;
      width: 100%;
      @media print {
        // display: block;
        width: auto;
        height: 900px;
        max-height: initial;
        // overflow: visible;

        .gantt_container {
          width: auto;
          height: auto;
          overflow: visible;
        }

        .gantt_grid {
          // display: block;
          width: auto;
          height: auto;
          overflow: visible;
        }

        .gantt_task {
          // display: block;
          width: auto;
          height: auto;
          overflow: visible;
        }

        .gantt_ver_scroll {
          opacity: 0;
        }
        .gantt_hor_scroll {
          opacity: 0;
        }
      }

      .project-gantt {
        height: 100%;
        width: 100%;
      }
    }
  }
}

.gantt_container {
  border-radius: 6px;
  /* -- Safari -- */
  .gantt_hor_scroll {
    min-height: 12px !important;
  }
  .gantt_ver_scroll {
    min-width: 12px !important;
  }
}

.project-gantt {
  .child_preview {
    box-sizing: border-box;
    margin-top: 2px;
    position: absolute;
    z-index: 1;
    color: white;
    text-align: center;
    font-size: 12px;
  }

  .gantt_task_line.task-collapsed {
    opacity: 0;
  }

  .gantt_task_line.task-collapsed .gantt_task_content {
    display: none;
  }

  .gantt_row.task-parent {
    font-weight: bold;
    .gantt_tree_indent {
      width: 45px;
    }
    .gantt_tree_icon {
      display: none;
    }
  }

  .gantt_tooltip {
    max-height: 150px;
    overflow-y: auto;
  }
}

.user-weeks-filter {
  > div {
    display: inline-block;
    margin-right: 15px;
    vertical-align: middle;
  }
}

.draggable-user {
  display: inline-block;
  background-color: white;
  padding: 10px;
  line-height: 100%;
  margin: 5px 10px 5px 0;
  cursor: move;
  border: 1px solid gainsboro;
  &.drag-process {
    background-color: #212121;
    color: white;
  }
  .p-chkbox {
    margin-top: -3px;
  }
}

.add-planned-work-user-week-summation {
  padding: 10px 15px 15px 15px;
  .overlay-table {
    table {
      width: 100%;
      .header-row {
        th {
          text-align: left;
          &:nth-child(3) {
            text-align: right;
          }
        }
      }
      tr {
        td {
          &:nth-child(3) {
            text-align: right;
          }
        }
      }
    }
  }
}

.inline-grid-label-form {
  .form-control-label {
    margin-top: 8px;
  }
}

/* --- Create ---*/
.create-project-tabview {
  .chose-establishment {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    z-index: 50;
    background: rgba(255, 255, 255, 0.8);
    justify-content: center;
    > div {
      padding: 15px;
      margin-top: 40px;
      // background: white;
      display: block;
      max-height: 100px;
      border-radius: 5px;
      text-align: center;
      p {
        margin-bottom: 15px;
      }
      // @include dropdown-shadow($dropdown-shadow);
    }
  }
}

.copy-to-establishment {
  float: right;
  font-size: 14px;
  font-weight: 400;
  i {
    font-size: 18px;
  }
}

/* --- General --- */
.project-title-section {
  h4,
  h3 {
    display: inline;
  }
}

.status-icons {
  span {
    vertical-align: middle;
    margin-right: 1px;
    i {
      vertical-align: text-top;
      font-size: 14px;
      &.inbox-red {
        color: $btn-danger-color;
      }
      &.th-list-green {
        color: $primary-btn-color;
      }
      &.end-date-passed {
        color: $btn-danger-color;
      }
      &.within-ten-days {
        color: $btn-warning-color;
      }
      &.outside-ten-days {
        color: $primary-btn-color;
      }
      &.outside-twenty-days {
        color: $success-btn-color;
      }
    }
  }
}

.column-title {
  margin-bottom: 10px;
  h4 {
    padding-bottom: 4px;
    border-bottom: solid 1px #cccece;
  }
}

.column-body {
  font-size: 13px;
  input {
    box-sizing: border-box;
  }
  .form-group {
    .form-control-label {
      font-size: 0.85em;
    }
    .p-chkbox-icon {
      &.fa {
        font-size: 15px;
      }
    }
  }
}

.checkbox-formgroup-space {
  height: 26.5px;
}

/* --- Project Content --- */
.short-message {
  width: 100%;
  &.has-error-message {
    // border: 1px solid $btn-danger-color;
    .p-inputgroup-addon {
      background-color: $btn-danger-color;
      border-color: $btn-danger-color;
      i {
        color: white;
      }
    }
    /* .p-inputtext{
            border: 2px solid $btn-danger-color;
        } */
  }
  .short-message-input {
    width: auto;
    overflow: hidden;
  }
}

.is-unmoved-rows {
  color: $btn-danger-color;
}

/* --- Project Main --- */

.gray-button {
  background-color: #a0a0a0 !important;
  border: none !important;
  &:hover {
    background-color: #8c8c8c !important;
  }
}

$expanded-row-border-color: #9ebfb9;

.expanded-row {
  border-color: $expanded-row-border-color !important;
}

.expanded-table-row {
  border-top-color: transparent !important;
  border-bottom: 1px $expanded-row-border-color solid !important;
}

/* ------ Project Days / material ------ */

// Column styles

.date-column {
  width: 17%;
  &.overview-col {
    width: 14%;
  }
}

.done-work-column {
  width: 33%;
  &.overview-col {
    width: 22%;
  }
}

th.word-break-in-th {
  word-break: break-word;
  -ms-word-break: break-word;
}

.created-row {
  -webkit-animation-name: blink;
  -webkit-animation-duration: 3000ms;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in-out;
}

.edit-panel {
  transition: color 0.4s;
  &:hover {
    color: $brand-primary-color;
    cursor: pointer;
  }
  &:nth-child(2) {
    margin-left: 0.3em;
  }
}

.column-info-ul {
  font-size: 13px;
  li {
    margin-bottom: 0.8em;
    div {
      display: inline-block;
      vertical-align: top;

      &:first-child {
        width: 35%;
        min-width: 135px;
        margin-right: 3px;
      }
    }
  }
}

// Underlag
.underlag-dialog {
  label {
    font-size: 0.8em;
    &:hover {
      cursor: pointer;
    }
  }
  li {
    padding: 0.25em 0 0.5em;
    border-bottom: $light-gray 1px solid;
    &:last-child {
      border: none;
    }
    input {
      float: right;
    }
  }
  .radio-buttons {
    ul {
      margin: 1em 0;
    }
  }
}

// Todo

.user-todo-relation-row {
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid lightgray;
  padding: 4px 5px;
  font-weight: 400;
}

.todo-autocomplete {
  textarea {
    margin-bottom: -16px;
  }
  .p-autocomplete-panel {
    top: -20px;
  }
  .hide-input {
    display: none !important;
  }
}

// ---- product settings
.product-settings {
  font-size: 0.9em;
  // -webkit-box-shadow: none;
  // -moz-box-shadow: none;
  // box-shadow: none;
}

// ---- users settings
// user list
.user-list {
  .p-togglebutton {
    padding: 0;
    background: $btn-danger-color;
    border: none !important;
    &:hover {
      filter: brightness(85%);
      //* darken($btn-danger-color, 10);
    }
    &.ui-state-active {
      background: $primary-btn-color;
      &:hover {
        filter: brightness(85%);
        //* darken($primary-btn-color, 10);
      }
    }
  }
}

.editable-table {
  th {
    -ms-word-break: break-all;
    word-break: break-all;

    /* Non standard for webkit */
    word-break: break-word;

    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
  }
  .p-inputtext {
    padding: 0.2em 0.7em;
  }
  .save {
    font-size: 1.4em;
  }
}

.salary-vacation-table {
  tbody {
    td {
      vertical-align: top;
    }
  }
}

.show-on-large-device {
  @media (max-width: 640px) {
    display: none;
  }
  @media (min-width: $break-md) {
    display: block;
  }
}

.show-on-small-device {
  @media (max-width: 640px) {
    display: block;
  }
  @media (min-width: $break-md) {
    display: none;
  }
}

.underline {
  text-decoration: underline;
}

hr.line-separator {
  border: 1px solid $line-separator-color;
  border-bottom: 0;
  margin: 1rem 0;
  width: 100%;
}
