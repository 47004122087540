@import '/assets/themes/struqtur/variables';

$table-border-color: #7878784d;
$cell-padding: 1rem;

th.invisible-header {
  visibility: hidden;
}

@mixin generate-prime-table-variation($variation) {
  body .p-#{$variation} {
    .p-#{$variation}-loading-overlay {
      background-color: rgba(255, 255, 255, 0.3);
    }

    .p-#{$variation}-wrapper {
      margin-bottom: 1rem;

      table {
        border-radius: 5px;
        border: 1px solid $table-border-color;
        border-spacing: 0;
        border-collapse: separate;
        width: 100%;

        .p-#{$variation}-thead {
          tr {
            background: $white;
            font-weight: 500;
            color: $text-color;

            th {
              padding: $cell-padding;
              text-align: left;
              white-space: nowrap;

              p-sorticon {
                float: right;
              }

              &.p-sortable-column {
                &.p-highlight {
                  background-color: $brand-secondary-color;
                  font-weight: 500;
                }
              }
            }
          }
        }

        .p-#{$variation}-tbody {
          tr {
            td {
              padding: $cell-padding;
              border-color: $table-border-color;
              white-space: nowrap;
            }
          }
        }

        tr {
          th,
          td {
            &.text-cell-center {
              text-align: center;
            }
            &.text-cell-right {
              text-align: right;
            }
          }
        }
      }

      table th,
      table td {
        border: 0;
      }

      /* Apply a border to the right of all but the last column */
      table th:not(:last-child),
      table td:not(:last-child) {
        border-right: 1px solid $table-border-color;
      }

      /* Apply a border to the bottom of all but the last row */
      table > thead > tr > th,
      table > thead > tr > td,
      table > tbody > tr:not(:last-child) > th,
      table > tbody > tr:not(:last-child) > td,
      table > tfoot > tr:not(:last-child) > th,
      table > tfoot > tr:not(:last-child) > td,
      table > tr:not(:last-child) > td,
      table > tr:not(:last-child) > th,
      table > thead:not(:last-child),
      table > tbody:not(:last-child),
      table > tfoot:not(:last-child) {
        border-bottom: 1px solid $table-border-color;
      }

      .p-#{$variation}-tfoot > tr > td {
        padding: 1rem;
        background-color: var(--primary-color-100);
        border-top: 1px solid $table-border-color;
      }

      tr:first-child > th:first-child,
      tr:first-child > td:first-child {
        border-radius: 6px 0 0 0;
      }

      tr:first-child > th:last-child,
      tr:first-child > td:last-child {
        border-radius: 0 6px 0 0;
      }
      tr:last-child > td:last-child {
        border-radius: 0 0 6px 0;
      }
      tr:last-child > td:first-child {
        border-radius: 0 0 0 6px;
      }
    }

    .p-paginator {
      margin: 1rem 0;
    }
  }

  body {
    .no-alternating
      .p-#{$variation}
      .p-#{$variation}-tbody
      > tr:nth-child(even) {
      background-color: initial;
    }

    .external-header {
      padding-top: 0;
      .p-#{$variation} {
        .p-#{$variation}-wrapper {
          tr:first-child() {
            td:last-child() {
              border-top-right-radius: 0;
            }
          }
        }
      }
    }

    .p-#{$variation} {
      .p-#{$variation}-wrapper {
        tr:only-child > th:only-child {
          border-radius: 6px 6px 0px 0px;
        }
        tr:only-child > td:only-child {
          border-radius: 0px 0px 6px 6px;
        }
      }
    }

    .header-only {
      padding-bottom: 0;
      margin-bottom: -3px;
      padding-left: 2px;
      border-bottom: none;
      .p-#{$variation} {
        .p-#{$variation}-wrapper {
          margin-bottom: 0;
          table {
            border-bottom: none;
            thead {
              border-bottom: none !important;
              tr > th {
                border-bottom: none;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
              }
            }
          }
        }
        margin: 0;
      }
    }

    p-table.inline-table,
    p-treetable.inline-table {
      .p-#{$variation}-thead tr th {
        background-color: var(--primary-color-100);
      }
    }

    .scrollable {
      @include scrollable(25rem, $variation);
      .p-#{$variation} {
        .p-#{$variation}-wrapper {
          height: 25rem;
        }
      }
    }
    .scrollable-tall {
      @include scrollable(40rem, $variation);
    }
    .scrollable-fullscreen {
      @include scrollable(80vh, $variation);
    }
  }
}

@mixin scrollable($height, $variation) {
  .p-#{$variation} {
    .p-#{$variation}-wrapper {
      max-height: $height;
      overflow-y: auto;

      .p-#{$variation}-table {
        border: none;
        th,
        td {
          &:first-child {
            border-left: 1px solid $table-border-color;
          }
          &:last-child {
            border-right: 1px solid $table-border-color;
          }
        }
        .p-#{$variation}-tbody {
          th,
          td {
            &:first-child {
              border-radius: 0;
            }
            &:last-child {
              border-radius: 0;
            }
          }
        }
      }

      .p-#{$variation}-thead {
        position: sticky;
        top: 0;
        z-index: 1;
        tr {
          th {
            border-top: 1px solid $table-border-color;
          }
        }
      }

      .p-#{$variation}-tfoot {
        position: sticky;
        bottom: 0;
        z-index: 1;
        tr {
          td {
            border-bottom: 1px solid $table-border-color;
          }
        }
      }
    }
  }
}

@include generate-prime-table-variation('datatable');
@include generate-prime-table-variation('treetable');

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1; /* Or 0, if you have other buttons in your tables */
  pointer-events: auto;
  content: '';
  background-color: rgba(0, 0, 0, 0);
}
