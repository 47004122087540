/*
	HYPERION _THEME
*/

.p-component-content a {
  color: $primary-btn-color;
}
.p-component-header {
  background: $background-gray;
}

body .p-panel {
  border: none !important;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  .p-panel-titlebar {
    border: transparent;
    padding: 0.857em 1em;
    background-color: transparent;
  }
}

hyperion-card {
  // self-made custom (in shared/card)
  &.responsive-show-small {
    // display: block;
    @media (min-width: $break-laptop) {
      .p-panel {
        background: none;
        padding: none;
        margin: none;
        box-shadow: none;
      }
    }
  }
  &.no-style {
    .p-panel {
      background: none !important;
      box-shadow: none !important;
      -webkit-box-shadow: none !important;
      -moz-box-shadow: none !important;
    }
  }
  // Header of p-panel
  .header {
    display: inline;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      display: inline;
      small {
        font-size: small;
        font-weight: 400;
        font-style: italic;
      }
    }
    &.flex-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      button {
        padding: 0.3em 0.6em;
      }
    }
    .label {
      // line-height: 100%;
      font-size: 0.7em;
      font-weight: 400;
      padding: 0.1em 0.5em;
      border-radius: 0.3em;
    }
    .header-icon {
      margin-left: 0.2em;
      &.help {
        font-size: 125%;
        position: absolute;
        margin-top: -0.1em;
      }
    }
    .p-component-header {
      background: transparent;
      background-color: transparent;
    }
  }
  .p-panel-titlebar-toggler {
    padding: 0.1em;
    background: $background-gray-darker !important;
  }
  p {
    white-space: pre-wrap;
  }
}
