.layout-footer {
    padding: 20px;
    background-color: $footerBgColor;
    border-top: 1px solid $footerBorderColor;

    img {
        height: 30px;
    }

    .footer-icons {
        text-align: right;

        a {
            color: $textSecondaryColor; 
            font-size: 24px;
            margin-right: 16px;
            @include transition(color $transitionDuration);

            &:hover {
                color: $textColor;
            }
        }
    }    
}