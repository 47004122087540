@import '/assets/themes/struqtur/variables';

body {
  .p-tabmenu {
    .p-tabmenu-nav {
      flex-flow: wrap;

      .p-tabmenuitem {
        border: none;
        border-bottom: 1px solid var(--primary-color-200);
        &:not(.ui-state-active):not(.p-disabled):hover,
        &.p-highlight {
          border: none;
          border-bottom: 3px solid var(--brand-primary-color);
          color: var(--primary-color-600);
        }
      }
    }
  }
}
