body {
  .p-panel {
    .p-panel-titlebar {
      .p-panel-titlebar-icon {
        float: right;
      }
    }
  }

  .p-card {
    padding: $element-padding * 1.5;
    box-shadow: none;
    border-radius: 6px;
    border: 1px solid $borderColor;
    height: 100%;
    h4 {
      margin: 0;
    }
    .p-card-title {
      color: var(--primary-color-600);
      font-weight: 700;
    }
  }
  .p-danger {
    .p-card {
      border-color: var(--btn-danger-color);
    }
  }

  .p-accordion-tab-active > .p-toggleable-content:not(.ng-animating) {
    overflow: visible;
  }

  .p-accordion {
    border-radius: 6px;
    border: 1px solid $borderColor;
    background-color: white;

    .p-accordion-tab-active {
      .p-accordion-header {
        .p-accordion-header-text {
          color: var(--brand-primary-color);
        }
      }
    }
    .p-accordion-header {
      margin-bottom: 0;

      a.p-accordion-toggle-icon {
        color: $borderColor;
      }

      &:not(.ui-state-active):not(.p-disabled) {
        a:hover,
        a:focus,
        a:active {
          background-color: transparent;
          border: 0;
          color: inherit;
        }
      }
      .p-accordion-header-link {
        flex-direction: row-reverse;
        justify-content: space-between;
        border: 0;
        background-color: transparent;
      }
      .p-accordion-header-text {
        transition: color 0.4s ease-in-out;
        color: black;
        font-weight: bold;
        padding: 0.6rem 0;
      }
    }

    .p-toggleable-content,
    .p-accordion-content {
      border: 0;
      padding-top: 0;
      background-color: transparent;
    }
  }

  .p-sidebar-right {
    .p-sidebar-content {
      overflow-x: hidden;
      overflow-y: auto;
      padding: 2rem 2rem 5rem 2rem;
    }
  }

  .p-messages {
    .p-message {
      border-color: var(--primary-color-300);
      border-radius: 6px;
      background-color: white;
      &.p-message-success {
        .p-message-icon {
          color: var(--btn-success-color);
        }
        border-left: 1rem solid var(--btn-success-color);
      }
      &.p-message-info {
        border-left: 1rem solid var(--btn-info-color);
        .p-message-icon {
          color: var(--btn-info-color);
        }
      }
      &.p-message-error {
        border-left: 1rem solid var(--btn-danger-color);
        .p-message-icon {
          color: var(--btn-danger-color);
        }
      }
      &.p-message-warning {
        border-left: 1rem solid var(--btn-warning-color);
        .p-message-icon {
          color: var(--btn-warning-color);
        }
      }
      .p-message-icon {
        font-size: 2rem;
        padding: 1rem;
      }
      .p-message-summary {
        font-size: 1.1rem;
      }
      .p-message-detail {
        padding: 1rem;
        line-height: 1rem;
      }
      .p-message-close {
        padding: 1rem;
      }
    }
  }

  p-tieredmenusub {
    display: block;
    max-height: 30vh;
    overflow-y: auto;
  }
}

body.enklakontoret {
  .p-accordion {
    .p-accordion-tab-active {
      .p-accordion-header {
        .p-accordion-header-text {
          color: var(--btn-primary-color);
        }
      }
    }
  }
}
