/*
	HYPERION _LAYOUT
*/
.hyperion-footer {
  padding-top: 1.5em;
  div {
    margin-bottom: 1em;
    line-height: 150%;
  }
  text-align: center;
  .hr {
    width: 900px;
    height: 1px;
    background-color: $background-gray-darker;
    @media (max-width: 950px) {
      width: 85%;
    }
    margin: 0 auto 1em;
  }
  .footer-head {
    color: $almost-black;
    font-weight: bold;
  }
  .footer-sub {
    color: $text-color;
    font-size: 0.9em;
  }
}

.index-page-header {
  margin-top: 20px;
  h1 {
    margin-bottom: 30px;
    margin-top: 0px;
    display: inline-block;
  }
  button {
    margin-left: 10px;
  }
}

.timeout-warning,
.locked-screen {
  .p-dialog-content {
    padding: 1.5em;
    text-align: center;
    div {
      margin-bottom: 1em;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .p-dialog-titlebar,
  .p-resizable-handle {
    display: none;
  }
}

.form-locked-screen {
  label {
    display: block;
    font-size: 0.8em;
    margin-bottom: 0.2em;
  }
}

// Dialog

.cdk-global-scrollblock {
  overflow-y: hidden;
}

.cdk-overlay-backdrop {
  overflow: -moz-scrollbars-none;
  overflow-y: hidden !important;
}

.dialog-container {
  width: calc(85vw - 48px);
  &.ultra-wide {
    width: calc(95vw - 48px);
  }
}

.cdk-overlay-container {
  z-index: 999;
}
.cdk-overlay-pane {
  display: inline-flex;
}

.dialog-title-row {
  margin-bottom: 10px;
  h2 {
    display: inline;
  }
  .close-btn {
    &:hover {
      cursor: pointer;
    }
  }
}

// PrimeNg
.p-dialog {
  .p-dialog-titlebar {
    .p-dialog-title {
      h3 {
        display: inline;
      }
    }
  }
}

.p-toast {
  z-index: 99999999 !important;
}
