@import '/assets/themes/legacy/style/variables';
@import '/assets/themes/ngtheme/sass/theme/variables';

$primaryColor: #2c9157;

$fontFamily: var(--font-family), 'Roboto', 'Open Sans', 'sans-serif';
$headingsFontFamily: var(--header-font-family), 'Roboto', 'Open Sans',
  'sans-serif';
$headingsColor: #111111;

$borderColor: #7878784d;
$text-medium-gray: $primaryColor500;
$text-light-gray: $primaryColor400;

$sidebar-light-gray: $primaryColor100;
$sidebar-text-color: $primaryColor600;
$sidebar-medium-gray: $primaryColor300;

$sidebar-height-breakpoint-1: 1090px;
$sidebar-height-breakpoint-2: 860px;
$borderRadius: 6px;
