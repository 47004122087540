/*
	HYPERION _ANI
*/
@keyframes MyAnimation {
  0% {
    top: 50px;
    // width: 50%;
  }
  100% {
    top: 0;
    // width: 100%;
  }
}

.my-animation {
  position: relative;
  animation: MyAnimation 1s;
}

@-webkit-keyframes blink {
  0% {
    background-color: $primary-btn-color;
    opacity: 1;
  }
  22% {
    background-color: $primary-btn-color;
  }
  77% {
    background-color: $primary-btn-color;
  }
  100% {
    background-color: initial;
    // background-color: inherit;
  }
}

@keyframes dropdown-blink-ani {
  50% {
    transform: scale(0.93);
  }
}
@-webkit-keyframes dropdown-blink-ani {
  50% {
    transform: scale(0.93);
  }
}

.dropdown-blink {
  .p-dropdown {
    animation: dropdown-blink-ani 0.7s linear 3;
    -webkit-animation: dropdown-blink-ani 0.7s linear 3;
  }
}

.slide-in {
  animation-duration: 1500ms;
  animation-name: slidein;
}

@keyframes slidein {
  from {
    margin-left: 100%;
  }

  to {
    margin-left: 0%;
  }
}

.scale-up {
  animation-duration: 1500ms;
  animation-name: scaleUp;
}

@keyframes scaleUp {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}
