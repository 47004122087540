// @todo remove when we have custom breakpoints in primeflex 3:
// https://app.shortcut.com/struqtur/story/16831
@media screen and (min-width: 1400px) {
  body {
    .p-xxl-1,
    .p-xxl-2,
    .p-xxl-3,
    .p-xxl-4,
    .p-xxl-5,
    .p-xxl-6,
    .p-xxl-7,
    .p-xxl-8,
    .p-xxl-9,
    .p-xxl-10,
    .p-xxl-11,
    .p-xxl-12 {
      flex: 0 0 auto;
    }

    .p-xxl-1 {
      width: 8.3333%;
    }

    .p-xxl-2 {
      width: 16.6667%;
    }

    .p-xxl-3 {
      width: 25%;
    }

    .p-xxl-4 {
      width: 33.3333%;
    }

    .p-xxl-5 {
      width: 41.6667%;
    }

    .p-xxl-6 {
      width: 50%;
    }

    .p-xxl-7 {
      width: 58.3333%;
    }

    .p-xxl-8 {
      width: 66.6667%;
    }

    .p-xxl-9 {
      width: 75%;
    }

    .p-xxl-10 {
      width: 83.3333%;
    }

    .p-xxl-11 {
      width: 91.6667%;
    }

    .p-xxl-12 {
      width: 100%;
    }
  }
}
@media screen and (min-width: 2100px) {
  body {
    .p-xxxl-1,
    .p-xxxl-2,
    .p-xxxl-3,
    .p-xxxl-4,
    .p-xxxl-5,
    .p-xxxl-6,
    .p-xxxl-7,
    .p-xxxl-8,
    .p-xxxl-9,
    .p-xxxl-10,
    .p-xxxl-11,
    .p-xxxl-12 {
      flex: 0 0 auto;
    }

    .p-xxxl-1 {
      width: 8.3333%;
    }

    .p-xxxl-2 {
      width: 16.6667%;
    }

    .p-xxxl-3 {
      width: 25%;
    }

    .p-xxxl-4 {
      width: 33.3333%;
    }

    .p-xxxl-5 {
      width: 41.6667%;
    }

    .p-xxxl-6 {
      width: 50%;
    }

    .p-xxxl-7 {
      width: 58.3333%;
    }

    .p-xxxl-8 {
      width: 66.6667%;
    }

    .p-xxxl-9 {
      width: 75%;
    }

    .p-xxxl-10 {
      width: 83.3333%;
    }

    .p-xxxl-11 {
      width: 91.6667%;
    }

    .p-xxxl-12 {
      width: 100%;
    }
  }
}
