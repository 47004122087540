@import '/assets/themes/struqtur/variables';

body {
  .p-dialog.p-confirm-dialog {
    max-width: 700px;

    .p-confirm-dialog-icon {
      font-size: 1.5rem;
      color: $text-light-gray;
      margin-right: 10px;
    }

    .p-dialog-content > span {
      line-height: 1.5rem;
    }
  }
}
