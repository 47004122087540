html {
    height: 100%;
}

body {
    font-family: $fontFamily;
    font-size: $fontSize;
    font-weight: 400;
    color: $textColor;
    -webkit-font-smoothing: antialiased;
    padding: 0;
    margin: 0;
    min-height: 100%;
    background-color: $bodyBgColor;
}

a {
    text-decoration: none;
}
