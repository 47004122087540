/*
	HYPERION _BASE
*/

.highcharts-credits {
  display: none;
}

body {
  margin: 0;
  padding: 0;
  background-color: $body-background-color;
  min-height: 100%;
  overflow: -moz-scrollbars-vertical;
  overflow-y: scroll;
  strong {
    font-weight: bold;
  }

  a,
  .link {
    text-decoration: none;
    color: $link-color;
    &:hover,
    &:active {
      color: $brand-primary-color;
    }
    &:hover {
      text-decoration: none;
      cursor: pointer;
    }
    &.close {
      color: $btn-danger-color;
    }
  }

  i,
  p,
  span {
    &.error {
      color: $btn-danger-color;
    }
    &.warning {
      color: $brand-primary-color;
    }
  }

  i {
    &.save,
    &.delete {
      &:hover {
        cursor: pointer;
      }
    }
    &.delete {
      color: $btn-danger-color;
    }
    &.save {
      transition: color 0.6s;
      &:hover {
        color: $primary-btn-color;
      }
    }
  }

  img {
    max-width: 100%;
  }

  .scrolly {
    overflow-y: scroll;
  }

  .no-show {
    display: none !important;
  }
  ul {
    list-style-type: none;
    padding-left: 0px;
  }

  .pull-right {
    float: right !important;
  }

  .align-center {
    text-align: center;
  }

  .align-left {
    text-align: left;
  }

  .align-right {
    text-align: right;
  }

  .light-text {
    font-weight: 100;
  }

  .no-margin {
    margin: 0 !important;
  }

  .text-center {
    text-align: center;
  }

  .block {
    width: 100%;
  }

  .clear-both {
    clear: both;
  }

  .transparent {
    opacity: 0.3;
    button {
      opacity: 1;
    }
  }
  .resize-vert {
    resize: vertical;
  }

  .inline-block {
    display: inline-block;
  }
}

/* --- Responsive classes --- */
.hide-xl {
  @media (min-width: $break-xl) {
    display: none !important;
  }
}
.show-xl {
  @media (max-width: 1440px) {
    display: none !important;
  }
}

.hide-lg {
  @media (min-width: $break-laptop) {
    display: none !important;
  }
}
.show-lg {
  @media (max-width: 1024px) {
    display: none !important;
  }
}

.hide-md {
  @media (min-width: $break-md) {
    display: none !important;
  }
}
.show-md {
  @media (max-width: 640px) {
    display: none !important;
  }
}

.autoWidth {
  width: auto !important;
}

.inline-block {
  display: inline-block;
}

.link-dark-background {
  color: white;
  text-shadow: 0px 1px 1px black;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
    color: white;
  }
  &.close {
    color: $btn-danger-color;
  }
}

.sidebar-left {
  padding-right: 0.7em;
}

.border-above {
  padding-top: 0.5em;
  border-top: 1px dotted lightgray;
}

.hover-pointer {
  cursor: pointer;
}

.hover-underline {
  &:hover {
    text-decoration: underline;
  }
}

.flex-container {
  display: -webkit-flex; /* Safari */
  -webkit-justify-content: space-between; /* Safari 6.1+ */
  display: flex;
  justify-content: space-between;
}

.search-model {
  margin-bottom: 1em;
  .searchbar {
    display: table;
    width: 100%;
    .search-field,
    .search-button {
      display: table-cell;
      vertical-align: middle;
      width: auto;
    }
    .search-button {
      width: 90px;
    }
    .search-field {
      padding-right: 10px;
    }
    .search-input {
      box-sizing: border-box;
      border-bottom: 2px solid $background-gray-darkest;
      background-color: none;
      background: none;
      padding: 0.7em;
      font-size: 0.9em;
      width: 100%;
    }
    i {
      margin-right: 0.2em;
    }
  }
}

.disabled {
  opacity: 0.6;
  cursor: not-allowed !important;
}

@media print {
  body {
    background-color: white;
    .print-no-show {
      display: none;
    }
  }
}

@page {
  size: auto; /* auto is the initial value */
  margin: 25mm 25mm 25mm 25mm;
}

.loading-screen {
  z-index: 699;
  background: #ebebeb3b;
  width: 100%;
  height: 100%;
  position: absolute;
  .bar {
    height: 2px;
    width: 100%;
    position: relative;
    overflow: hidden;
    background-color: #dddddd69;
    &:before {
      display: block;
      position: absolute;
      content: '';
      left: -200px;
      width: 200px;
      height: 4px;
      background-color: #a9afb3;
      animation: loadingScreen 2s linear infinite;
    }
  }
}

@-webkit-keyframes loadingScreen {
  from {
    left: -200px;
    width: 30%;
  }
  50% {
    width: 30%;
  }
  70% {
    width: 70%;
  }
  80% {
    left: 50%;
  }
  95% {
    left: 120%;
  }
  to {
    left: 100%;
  }
}
@keyframes loadingScreen {
  from {
    left: -200px;
    width: 30%;
  }
  50% {
    width: 30%;
  }
  70% {
    width: 70%;
  }
  80% {
    left: 50%;
  }
  95% {
    left: 120%;
  }
  to {
    left: 100%;
  }
}

.pdf-container {
  height: 700px;
  .pdf-view-object {
    height: 100%;
    width: 100%;
    border: solid thin #000;
  }
}

.pdf-container-second {
  .pdf-view-object {
    height: 700px;
    width: 100%;
    border: solid thin #000;
    @media (min-width: 1026px) and (max-width: 1599px) {
      height: 550px;
    }
  }
}

.preview-container {
  width: 100%;
  height: 400px;
  iframe {
    width: 100%;
    height: 100%;
  }
}

.red {
  color: $btn-danger-color;
}

.shadow-card {
  -webkit-box-shadow: -1px 1px 5px 0px rgba(184, 184, 184, 1);
  -moz-box-shadow: -1px 1px 5px 0px rgba(184, 184, 184, 1);
  box-shadow: -1px 1px 5px 0px rgba(184, 184, 184, 1);
  padding: 10px;
  margin-bottom: 15px;
  cursor: pointer;
  &:hover {
    .action-icon {
      i {
        color: $primary-btn-color;
      }
    }
  }

  .card-content {
    p {
      font-style: italic;
      font-size: 14px;
    }
  }
  .action-icon {
    i {
      vertical-align: middle;
      font-size: 35px;
    }
    &.active-choice {
      i {
        color: $primary-btn-color;
        font-size: 55px;
      }
    }
  }
}

/* --- INTROJS ---*/
.introjs-tooltip {
  min-width: 400px;
}

.introjs-skipbutton {
  float: left;
}

.introjs-progressbar {
  background-color: $primary-btn-color;
}

.introjs-helperLayer {
  background-color: rgba(255, 255, 255, 0.2);
}

/* --- Help Tooltip --- */
.help-tooltip {
  font-size: 14px !important;
  font-weight: 400;
  font-family: 'open sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  text-align: center;
  background: $success-btn-color;
  color: white;
  border-radius: 50%;
  height: 18px;
  width: 18px;
  display: inline-block;
  @include box-shadow($shadow-small);
  cursor: help;
  &.invert {
    background: white;
    color: $success-btn-color;
  }
  &.smaller {
    font-size: 12px !important;
    line-height: 16px;
    height: 16px;
    width: 16px;
  }
  &.bigger {
    font-size: 16px !important;
    line-height: 20px;
    height: 20px;
    width: 20px;
  }
}

h1,
h2,
h3,
h4 {
  .help-tooltip {
    vertical-align: middle;
  }
}

.header-with-background {
  background-color: $table-head-color;
  color: white;
  padding: 2px 5px;
}

.top-border {
  border-top: 1px solid lightgray;
}

.thin-weight {
  font-weight: 100;
}

.bold-weight {
  font-weight: 600;
} // Leet

.extra-bold-weight {
  font-weight: 700;
}

.dimmed {
  opacity: 0.5;
}

.space-between {
  display: -webkit-flex; /* Safari */
  -webkit-justify-content: space-between; /* Safari 6.1+ */
  display: flex;
  justify-content: space-between;
}

.text-color {
  color: $text-color;
}

.p-inputgroup {
  p-autocomplete {
    flex-grow: 100;
  }
  hyperion-autocomplete {
    flex-grow: 100;
  }
  input {
    flex-grow: 100;
  }
}

.break-words {
  word-break: break-word;
  -ms-word-break: break-word;
}

.transparent {
  opacity: 0.3;
}

.table-cell-normal-font-size {
  .p-cell-data {
    font-size: 1em;
  }
}

.row-in-card-md {
  @media (max-width: 640px) {
    margin-bottom: 20px;
    &:not(.no-border) {
      border: 1px solid $light-gray;
    }
  }
}

.row-in-card-lg {
  @media (max-width: 1024px) {
    margin-bottom: 20px;
    &:not(.no-border) {
      border: 1px solid $light-gray;
    }
  }
}

.row-in-card-xl {
  @media (max-width: 1440px) {
    margin-bottom: 20px;
    &:not(.no-border) {
      border: 1px solid $light-gray;
    }
  }
}

.dnd-sortable-drag {
  transform: scale(0.8);
}

.transparent-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  display: -webkit-flex; /* Safari */
  z-index: 50;
  background: rgba(255, 255, 255, 0.8);
  -webkit-justify-content: center; /* Safari 6.1+ */
  justify-content: center;
  -webkit-align-items: center; /* Safari 6.1+ */
  align-items: center;
  > div {
    padding: 15px;
    // margin-top: 40px;
    // background: white;
    display: block;
    max-height: 100px;
    border-radius: 5px;
    text-align: center;
    p {
      margin-bottom: 15px;
    }
    // @include dropdown-shadow($dropdown-shadow);
  }
}

.expansion-container {
  > div {
    float: left;
    padding: 0.25em 0.5em;
    > div {
      font-size: 0.8em;
    }
  }
}

.word-break {
  word-break: break-word;
}

label.bold-label {
  font-weight: bold;
}

.label-padding-md {
  padding-top: 18px;
  @media (max-width: $break-md) {
    padding-top: 0;
  }
}

.flex-vertical-align {
  display: flex;
  align-items: center;
}

.display-as-inline-block {
  display: inline-block;
}

.display-none {
  display: none;
}

.notice-number {
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  // padding: 3px;
  line-height: 20px;
  font-size: 12px;
  &.has-msg {
    margin-left: 3px;
    width: 20px;
    background-color: $btn-danger-color;
    color: #fff;
  }
}

.space-beetwen {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.fake-card {
  background-color: white;
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
}

.p-component-overlay-enter {
  animation: p-component-overlay-enter-animation 150ms forwards;
}

.p-component-overlay-leave {
  animation: p-component-overlay-leave-animation 150ms forwards;
}

@keyframes p-component-overlay-enter-animation {
  from {
    background-color: transparent;
  }
  to {
    background-color: var(--maskbg);
  }
}

@keyframes p-component-overlay-leave-animation {
  from {
    background-color: var(--maskbg);
  }
  to {
    background-color: transparent;
  }
}

:root {
  --maskbg: rgba(0, 0, 0, 0.4);
}

.p-sidebar-right {
  min-width: 40rem;
  max-width: 90%;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  .p-sidebar-content {
    height: 100%;
    overflow: hidden;
  }
}
