@import '/assets/themes/ngtheme/sass/variables';

//main
$bodyBgColor:#f4f4f4;

$footerBgColor:#ffffff;
$footerBorderColor:#dadada;

//light menu
$menuBgColor:#ffffff;
$menuitemTextColor:#666666;
$menuitemIconColor:#a6a6a6;
$menuitemHoverBgColor:#eaeaea;
$menuitemIconHoverColor:#666666;
$rootMenuitemActiveBgColor:$primaryColor;
$rootMenuitemActiveIconColor:$primaryTextColor;
$rootMenuitemActiveTextColor:$primaryTextColor;
$submenuItemActiveColor:$primaryTextColor;
$submenuitemBgColor:transparent;

//dark menu
$darkMenuBgColor:#333333;
$darkMenuitemTextColor:#c8c8c8;
$darkMenuitemIconColor:#a6a6a6;
$darkMenuitemHoverBgColor:#474747;
$darkMenuitemIconHoverColor:#dadada;
$darkRootMenuitemActiveBgColor:$primaryColor;
$darkRootMenuitemActiveIconColor:$primaryTextColor;
$darkRootMenuitemActiveTextColor:$primaryTextColor;
$darkSubmenuItemActiveColor:$primaryTextColor;
$darkSubmenuitemBgColor:#282828;

$slimMenuTooltipBgColor:#333333;
$slimMenuTooltipTextColor:#c8c8c8;
