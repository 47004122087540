@import '/assets/themes/legacy/style/button';
@import '/assets/themes/struqtur/variables';

$button-text-color: #111111;
$hover-background-color: $primaryColor300;

body {
  p-splitButton {
    .p-button.p-splitbutton-menubutton {
      border-left: none;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }

  .p-button:not(p-splitButton) {
    &.wide {
      .p-button-icon.p-button-icon-right {
        margin-left: 7rem;
      }
    }
    border-radius: 6px;
    padding: 1rem;
    background: $white;
    font-weight: bold;
    color: $button-text-color;
    white-space: nowrap;

    &:enabled {
      &:hover,
      &:active {
        background-color: $hover-background-color;
        color: $button-text-color;
      }
    }

    &.p-button-text {
      background-color: inherit;
      border: none;
    }

    .p-button-icon {
      font-size: 0.8rem;
      width: 0.8rem;
      height: 0.8rem;
      line-height: normal;
      &.p-button-icon-left {
        margin-right: 0.8rem;
      }

      &.p-button-icon-right {
        margin-left: 0.8rem;
      }
    }
  }

  .p-calendar.p-calendar-w-btn {
    .p-datepicker-trigger.p-button {
      position: relative;
      padding: 1rem;
    }
  }

  .btn-notification {
    display: inline-flex;

    .badge {
      top: -5px;
      right: -10px;
    }
  }

  p-button {
    &.p-button-sm {
      .p-button {
        font-size: 0.7rem;
        padding: 0.6rem 0.7rem;
      }
    }
  }
}
