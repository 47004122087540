@import '/assets/themes/struqtur/variables';

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $headingsFontFamily;
  letter-spacing: 1px;
}

h1 {
  font-weight: var(--header-weight) !important;
  text-transform: var(--top-menu-text-transform);
}

body {
  font-family: $fontFamily, sans-serif;
}

h3,
h4,
h5,
h6 {
  font-weight: bold;
  color: $headingsColor;
}
