body {
  .p-treetable,
  .p-datatable {
    .p-treetable-wrapper,
    .p-datatable-wrapper {
      .p-treetable-thead > tr > th,
      .p-datatable-thead > tr > th {
        color: #000000;
        font-weight: 500;

        .p-highlight,
        .p-sortable-column.p-highlight {
          color: #000000;
          font-weight: 500;
        }

        &:hover {
          color: #000000;
        }
      }

      .p-treetable-tfoot > tr > td,
      .p-datatable-tfoot > tr > td {
        color: #000000;
        font-weight: 500;

        .p-highlight,
        .p-sortable-column.p-highlight {
          color: #000000;
          font-weight: 500;
        }
      }
    }
  }

  .p-paginator {
    border-radius: 6px;

    .p-paginator-pages {
      .p-paginator-page {
        color: var(--primary-color-300);

        &.p-highlight {
          color: var(--brand-primary-color);
        }
      }
    }
  }

  .p-tree {
    .p-treenode-children {
      padding-left: 1rem;
    }

    .p-treenode {
      .p-treenode-content {
        padding: 0.25rem 0.5rem;
      }
    }
  }
}
