table {
  width: 100%;
}

.table-with-inputs {
  tr:not(.p-component-content) > td table thead {
    display: none;
  }
  &.product-table tr:not(.p-component-content) > td table thead {
    display: table-row-group;
  }
}

.p-cell-editor {
  .p-inputtext {
    font-size: 0.8em;
    padding: 0.3em 0.7em;
  }
}

.table-no-style {
  border: none !important;
  border-style: none !important;
  border-width: 0 !important;
  tr,
  td {
    border: none !important;
    border-style: none !important;
    border-width: 0 !important;
  }
  td {
    padding: 2px 6px !important;
  }
}

.td-align-left {
  tr {
    td {
      text-align: left;
      // padding-right: 3px!important;
    }
  }
}

table tbody tr.gray-row {
  background: #5d5d5d;
  color: white;
}

.td-align-bottom {
  table {
    tr {
      td {
        vertical-align: bottom;
      }
    }
  }
}

.thead-word-break {
  table {
    thead {
      tr {
        th {
          word-break: break-word;
          -ms-word-break: break-word;
        }
      }
    }
  }
}

/* transactionsEditTable */
.transactionsEditTable {
  .p-datatable > .p-datatable-wrapper > table > thead > tr,
  .p-datatable > .p-datatable-wrapper > table > .p-datatable-tfoot > tr {
    background-color: #747474;
  }
  .form-control-label {
    font-size: 0.95em !important;
    margin-bottom: 0.2em !important;
    font-weight: 100 !important;
  }
  tbody tr {
    border-bottom: solid thin #e2e2e2;
    background-color: #f9f9f9 !important;
  }
  td {
    border-left: solid thin #e2e2e2;
    padding: 0px !important;
    height: 19px !important;
    margin: 0px 0px 0px 0px !important;
  }

  input[type='text']:focus,
  textarea:focus,
  select:focus,
  .p-dropdown:focus,
  .p-dropdown:active {
    background-color: #e6ffe6 !important;
    border: solid 1px #bdf6bd !important;
  }
  textarea:focus {
    width: 200%;
    position: relative;
    z-index: 20;
  }

  .p-dropdown {
    min-width: auto;
    margin: 0px !important;
    width: 100%;
    label {
      padding: 3px !important;
      text-align: left !important;
    }
    .fa-caret-down {
      margin-top: 3px !important;
    }
    &:hover .p-dropdown-trigger {
      background: #b3d59c !important;
    }
  }

  input,
  textarea,
  .p-dropdown {
    height: 100%;
    background-color: transparent !important;
    border: solid 1px rgba(255, 255, 255, 0.1) !important;
  }

  textarea {
    min-height: 20px !important;
    &:focus {
      min-height: 75px !important;
    }
  }

  .fa-trash-o:hover {
    color: #f00;
    cursor: pointer;
  }

  input:disabled {
    background-color: #e7e7e7 !important;
  }
  tbody td:hover {
    background-color: #f2fff2 !important;
  }

  input,
  textarea {
    padding: 2px !important;
    margin-bottom: 0px !important;
    width: 100%;
  }

  .subTbl tbody td:hover {
    background-color: transparent !important;
  }
  .subTbl tbody tr:hover {
    background-color: #ccc !important;
    cursor: pointer;
  }
}
