* {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    .p-component {
        font-family: $fontFamily;
        font-size: $fontSize;
        text-decoration: none;
    }

    .ui-corner-all {
        @include border-radius($borderRadius);
    }

    .ui-corner-top {
        @include border-radius-top($borderRadius);
    }

    .ui-corner-bottom {
        @include border-radius-bottom($borderRadius);
    }

    .ui-corner-left {
        @include border-radius-left($borderRadius);
    }

    .ui-corner-right {
        @include border-radius-right($borderRadius);
    }

    a {
        color: $linkColor;
        text-decoration: none;

        &:hover {
            color: $linkHoverColor;
        }

        &:active {
            color: $linkActiveColor;
        }
    }

    .p-reset {
        line-height: normal;
    }

    .p-disabled, .p-component:disabled {
        @include opacity($disabledOpacity);
    }

    .pi {
        font-size: $primeIconFontSize;
    }
}
