@font-face {
  font-family: 'Gruffy';
  font-style: normal;
  font-weight: 300;
  src: url('../../../assets/themes/struqtur/fonts/F37Gruffy-Regular/F37Gruffy-Regular.eot'); /* IE9 Compat Modes */
  src: local('Gruffy'),
    url('../../../assets/themes/struqtur/fonts/F37Gruffy-Regular/F37Gruffy-Regular.eot')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('../../../assets/themes/struqtur/fonts/F37Gruffy-Regular/F37Gruffy-Regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../../../assets/themes/struqtur/fonts/F37Gruffy-Regular/F37Gruffy-Regular.woff')
      format('woff'),
    /* Modern Browsers */
      url('../../../assets/themes/struqtur/fonts/F37Gruffy-Regular/F37Gruffy-Regular.ttf')
      format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Plain';
  src: url('../../../assets/themes/struqtur/fonts/Plain/Plain-Bold/Plain-Bold.eot');
  src: url('../../../assets/themes/struqtur/fonts/Plain/Plain-Bold/Plain-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('../../../assets/themes/struqtur/fonts/Plain/Plain-Bold/Plain-Bold.woff2')
      format('woff2'),
    url('../../../assets/themes/struqtur/fonts/Plain/Plain-Bold/Plain-Bold.woff')
      format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Plain';
  src: url('../../../assets/themes/struqtur/fonts/Plain/Plain-Medium/Plain-Medium.otf')
    format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Plain';
  src: url('../../../assets/themes/struqtur/fonts/Plain/Plain-Light/Plain-Light.eot');
  src: url('../../../assets/themes/struqtur/fonts/Plain/Plain-Light/Plain-Light.eot?#iefix')
      format('embedded-opentype'),
    url('../../../assets/themes/struqtur/fonts/Plain/Plain-Light/Plain-Light.woff2')
      format('woff2'),
    url('../../../assets/themes/struqtur/fonts/Plain/Plain-Light/Plain-Light.woff')
      format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Plain';
  src: url('../../../assets/themes/struqtur/fonts/Plain/Plain-Regular/Plain-Regular.eot');
  src: url('../../../assets/themes/struqtur/fonts/Plain/Plain-Regular/Plain-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('../../../assets/themes/struqtur/fonts/Plain/Plain-Regular/Plain-Regular.woff2')
      format('woff2'),
    url('../../../assets/themes/struqtur/fonts/Plain/Plain-Regular/Plain-Regular.woff')
      format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Derome Sans';
  src: url('../../../assets/themes/struqtur/fonts/Derome/Derome-Regular.ttf')
    format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Derome Sans';
  src: url('../../../assets/themes/struqtur/fonts/Derome/Derome-Light.ttf')
    format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Derome Sans';
  src: url('../../../assets/themes/struqtur/fonts/Derome/Derome-Bold.otf')
    format('opentype');
  font-weight: 700;
}
