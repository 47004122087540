// Variables
@import '/assets/themes/struqtur/fonts';
@import '/assets/themes/struqtur/variables';

// Base theme
@import '/assets/themes/ngtheme/sass/theme_core/core';
@import '/assets/themes/ngtheme/sass/layout/layout';

// Legacy Styles
@import '/assets/themes/legacy/style/mixins';
@import '/assets/themes/legacy/style/base';
@import '/assets/themes/legacy/style/layout';
@import '/assets/themes/legacy/style/menu';
@import '/assets/themes/legacy/style/spinners';
@import '/assets/themes/legacy/style/animations';
@import '/assets/themes/legacy/style/components';
@import '/assets/themes/legacy/style/theme';
@import '/assets/themes/legacy/style/form';
@import '/assets/themes/legacy/style/table';
@import '/assets/themes/legacy/style/hyperion-pre-init-assets';

// Styles
@import '/assets/themes/struqtur/styles/typographies';
@import '/assets/themes/struqtur/styles/buttons';
@import '/assets/themes/struqtur/styles/panels';
@import '/assets/themes/struqtur/styles/tables';
@import '/assets/themes/struqtur/styles/datepicker';
@import '/assets/themes/struqtur/styles/forms';
@import '/assets/themes/struqtur/styles/tabmenu';
@import '/assets/themes/struqtur/styles/data';
@import '/assets/themes/struqtur/styles/grid';
@import '/assets/themes/struqtur/styles/dialogs';

// Global Hacks/Fixes below this line

.p-dropdown {
  min-width: 0;
}

.p-dropdown-label-container {
  width: 100%;

  .p-dropdown-label {
    text-overflow: ellipsis;
  }
}

.page-container {
  padding: 1rem;
  width: 100%;
}

.p-dialog-header {
  background-color: white;
  font-family: $headingsFontFamily;
  font-size: 1.5rem;
  font-weight: bold;
  padding: 1rem;
  border-radius: 6px 6px 0 0;
}
.p-dialog-content:last-child {
  border-radius: 0 0 6px 6px;
}
.p-dialog-footer {
  border-radius: 0 0 6px 6px;

  .p-icon-wrapper {
    display: none;
  }

  .p-confirm-dialog-accept {
    background-color: $primaryColor;
    color: var(--primary-color-100);
  }
}

body {
  .dark-background {
    .p-dialog-content {
      background-color: var(--primary-color-100);
    }
  }
}

.p-divider.p-divider-horizontal::before {
  border-top: 1px none var(--primary-color-300);
}
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: var(--primary-color-400);
  border-radius: 6px;
  border: 4px solid transparent;
  background-clip: padding-box;
  transition: all ease-in-out 0.2s;
}
::-webkit-scrollbar-thumb:hover {
  border: 0;
}

app-header {
  position: fixed;
  transition: all ease-in-out 0.2s;
  z-index: 1000;
  top: 0;
  right: 0;
  left: 8rem;

  &.scroll-up {
    transform: translateY(0);
  }

  &.scroll-down {
    transform: translateY(-100%);
  }
}

body {
  .p-grid {
    margin: 0;
  }
}
.p-tooltip {
  pointer-events: none;
}
