body {
  .p-calendar {
    .p-datepicker {
      width: 300px;

      .p-datepicker-group-container {
        .p-datepicker-group {
          .p-datepicker-calendar-container {
            .p-datepicker-calendar {
              tbody tr td {
                padding: 0.25rem;
              }
            }
          }
        }
      }
    }
  }
}
