.form-group.has-danger {
  div.ng-star-inserted {
    position: relative;
    left: 10px;
    top: 2px;
    z-index: 9999900000;
    &:visited {
      display: none;
    }
  }
  .form-control {
    margin: -2px;
  }
}

div.ng-star-inserted {
  p.form-control-feedback.ng-star-inserted {
    color: #ffeaea;
    position: absolute;
    max-width: 360px;
    min-width: 150px;
    padding: 3px;
    background-color: #f25858;
    opacity: 0.94;
    margin: 1px;
    font-size: 13px;
    box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.58);
    i.fa {
      margin-right: 3px;
    }
    &:before {
      content: '';
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 7px solid #f25858;
      position: absolute;
      top: -6px;
    }
  }
}

.form-error-message {
  color: $form-error-message-color;
}

input[readonly] {
  pointer-events: none;
  color: $input-disabled-text-color;
}
