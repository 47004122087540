/* lato-300 - latin-ext_latin */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 300;
    src: url("../../../../assets/themes/ngtheme/layout/fonts/lato-v14-latin-ext_latin-300.eot"); /* IE9 Compat Modes */
    src: local('Lato Light'), local('Lato-Light'),
         url("../../../../assets/themes/ngtheme/layout/fonts/lato-v14-latin-ext_latin-300.eot") format('embedded-opentype'), /* IE6-IE8 */
         url("../../../../assets/themes/ngtheme/layout/fonts/lato-v14-latin-ext_latin-300.woff2") format('woff2'), /* Super Modern Browsers */
         url("../../../../assets/themes/ngtheme/layout/fonts/lato-v14-latin-ext_latin-300.woff") format('woff'), /* Modern Browsers */
         url("../../../../assets/themes/ngtheme/layout/fonts/lato-v14-latin-ext_latin-300.ttf") format('truetype'), /* Safari, Android, iOS */
         url("../../../../assets/themes/ngtheme/layout/fonts/lato-v14-latin-ext_latin-300.svg") format('svg'); /* Legacy iOS */
  }
  /* lato-regular - latin-ext_latin */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: url("../../../../assets/themes/ngtheme/layout/fonts/lato-v14-latin-ext_latin-regular.eot"); /* IE9 Compat Modes */
    src: local('Lato Regular'), local('Lato-Regular'),
         url("../../../../assets/themes/ngtheme/layout/fonts/lato-v14-latin-ext_latin-regular.eot") format('embedded-opentype'), /* IE6-IE8 */
         url("../../../../assets/themes/ngtheme/layout/fonts/lato-v14-latin-ext_latin-regular.woff2") format('woff2'), /* Super Modern Browsers */
         url("../../../../assets/themes/ngtheme/layout/fonts/lato-v14-latin-ext_latin-regular.woff") format('woff'), /* Modern Browsers */
         url("../../../../assets/themes/ngtheme/layout/fonts/lato-v14-latin-ext_latin-regular.ttf") format('truetype'), /* Safari, Android, iOS */
         url("../../../../assets/themes/ngtheme/layout/fonts/lato-v14-latin-ext_latin-regular.svg") format('svg'); /* Legacy iOS */
  }
  /* lato-700 - latin-ext_latin */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    src: url("../../../../assets/themes/ngtheme/layout/fonts/lato-v14-latin-ext_latin-700.eot"); /* IE9 Compat Modes */
    src: local('Lato Bold'), local('Lato-Bold'),
         url("../../../../assets/themes/ngtheme/layout/fonts/lato-v14-latin-ext_latin-700.eot") format('embedded-opentype'), /* IE6-IE8 */
         url("../../../../assets/themes/ngtheme/layout/fonts/lato-v14-latin-ext_latin-700.woff2") format('woff2'), /* Super Modern Browsers */
         url("../../../../assets/themes/ngtheme/layout/fonts/lato-v14-latin-ext_latin-700.woff") format('woff'), /* Modern Browsers */
         url("../../../../assets/themes/ngtheme/layout/fonts/lato-v14-latin-ext_latin-700.ttf") format('truetype'), /* Safari, Android, iOS */
         url("../../../../assets/themes/ngtheme/layout/fonts/lato-v14-latin-ext_latin-700.svg") format('svg'); /* Legacy iOS */
  }
