body {
    .p-inputtext {
        font-size: $fontSize;
        color: $textColor;
        background: $inputBgColor;
        padding: $inputPadding;
        border: $inputBorder;
        @include transition($inputTransition);
        @include appearance(none);

        &:enabled:hover:not(.ng-dirty) {
            border-color: $inputHoverBorderColor;
        }

        &:enabled:focus:not(.ng-dirty) {
            @include focused-input();
        }
    }

    .p-checkbox {
        display: inline-block;
        vertical-align: middle;
        margin: 0;
        width: $checkboxWidth;
        height: $checkboxHeight;

        .p-checkbox-box {
            border: $inputBorder;
            background-color: $inputBgColor;
            width: $checkboxWidth;
            height: $checkboxHeight;
            text-align: center;
            @include border-radius($borderRadius);
            @include transition($checkboxTransition);

            &:not(.p-disabled):hover {
                border-color: $inputHoverBorderColor;
            }

            &.p-focus {
                border-color: $checkboxFocusBorderColor;
                background-color: $checkboxFocusBgColor;
                color: $checkboxFocusTextColor;
            }

            &.ui-state-active {
                border-color: $checkboxActiveBorderColor;
                background-color: $checkboxActiveBgColor;
                color: $checkboxActiveTextColor;

                &:not(.p-disabled):hover {
                    background-color: $checkboxActiveHoverBgColor;
                    border-color: $checkboxActiveBorderColor;
                }

                &.p-focus {
                    border-color: $checkboxActiveFocusBorderColor;
                    background-color: $checkboxActiveFocusBgColor;
                    color: $checkboxActiveFocusTextColor;
                }
            }

            .p-checkbox-icon {
                overflow: hidden;
                position: relative;
                font-size: $fontSize + 4;
            }
        }
    }

    .p-checkbox-label {
        margin: 0 0 0 $inlineSpacing;
    }

    .p-radiobutton {
        display: inline-block;
        vertical-align: middle;
        margin: 0;
        width: $radiobuttonWidth;
        height: $radiobuttonHeight;

        .p-radiobutton-box {
            border: $inputBorder;
            background-color: $inputBgColor;
            width: $radiobuttonWidth;
            height: $radiobuttonHeight;
            text-align: center;
            position: relative;
            @include transition($radiobuttonTransition);
            @include border-radius(50%);

            &:not(.p-disabled):not(.ui-state-active):hover {
                border-color: $inputHoverBorderColor;
            }

            &.p-focus {
                border-color: $radiobuttonFocusBorderColor;
                background-color: $radiobuttonFocusBgColor;
                color: $radiobuttonFocusTextColor;
            }

            &.ui-state-active {
                border-color: $radiobuttonActiveBorderColor;
                background-color: $radiobuttonActiveBgColor;
                color: $radiobuttonActiveTextColor;

                .p-radiobutton-icon {
                    background-color: $radiobuttonActiveTextColor;
                }

                &:not(.p-disabled):hover {
                    border-color: $radiobuttonActiveHoverBorderColor;
                    background-color: $radiobuttonActiveHoverBgColor;
                    color: $radiobuttonActiveHoverTextColor;
                }

                &.p-focus {
                    border-color: $radiobuttonActiveFocusBorderColor;
                    background-color: $radiobuttonActiveFocusBgColor;
                    color: $radiobuttonActiveFocusTextColor;
                }
            }

            .p-radiobutton-icon {
                background: transparent;
                width: $radiobuttonWidth / 2;
                height: $radiobuttonHeight / 2;
                display: inline-block;
                position: absolute;
                left: 50%;
                top: 50%;
                margin-left: -1 * $radiobuttonWidth / 4;
                margin-top: -1 * $radiobuttonWidth / 4;
                @include transition(background-color $transitionDuration);
                @include border-radius(50%);

                &:before {
                    display: none;
                }
            }
        }
    }

    .p-radiobutton-label {
        margin: 0 0 0 .5em;
    }

    .p-inputswitch {
        width: $inputSwitchWidth;
        height: $inputSwitchHeight;

        .p-inputswitch-slider {
            @include transition($inputSwitchTransition);
            border-radius: $inputSwitchBorderRadius;
            background: $inputSwitchSliderOffBgColor;
            border: $inputBorder;

            &:before {
                background-color: $inputSwitchHandleOffBgColor;
                height: 1.429em;
                width: 1.429em;
                left: 0;
                bottom: -0.286em;
                border-radius: 50%;
                -webkit-transition: $transitionDuration;
                transition: $transitionDuration;
            }
        }

        &.p-inputswitch-checked {
            .p-inputswitch-slider:before {
                -webkit-transform: translateX(1.250em);
                -ms-transform: translateX(1.250em);
                transform: translateX(1.250em);
            }
        }

        &.p-inputswitch-focus {
            .p-inputswitch-slider {
                background: $inputSwitchSliderOffFocusBgColor;

                &:before {
                    background-color: $inputSwitchHandleOffFocusBgColor;
                }
            }
        }

        &:not(.p-disabled):hover {
            .p-inputswitch-slider {
                background-color: $inputSwitchSliderOffHoverBgColor;
            }
        }

        &.p-inputswitch-checked {
            .p-inputswitch-slider {
                background-color: $inputSwitchSliderOnBgColor;
                border-color: $primaryColor200;

                &:before {
                    background-color: $inputSwitchHandleOnBgColor;
                }
            }

            &:not(.p-disabled):hover {
                .p-inputswitch-slider {
                    background-color: $inputSwitchSliderOnHoverBgColor;
                }
            }

            &.p-inputswitch-focus {
                .p-inputswitch-slider {
                    background: $inputSwitchSliderOnFocusBgColor;

                    &:before {
                        background-color: $inputSwitchHandleonFocusBgColor;
                    }
                }
            }
        }
    }

    .p-autocomplete {
        .p-autocomplete-dropdown {
            .p-button-icon-left {
                @include icon_override("\e902");
            }
        }

        .p-autocomplete-input {
            padding: $inputPadding;
        }

        .p-autocomplete-panel .p-autocomplete-list .autocomplete-row>div {
            display: block;
            text-align: left;
            padding: 0 3px;
            word-break: break-word;
            float: left;
        }
        .p-autocomplete-panel .p-autocomplete-list .autocomplete-row.autocomplete-header-row {
            background: #5d5d5d;
            color: #fff;
            cursor: default;
        }

        .p-autocomplete-panel .p-autocomplete-list .autocomplete-header-row {
            display: none;
        }
        .p-autocomplete-panel .p-autocomplete-list .p-autocomplete-list-item:first-child .autocomplete-header-row {
            display: block;
        }

        &.p-autocomplete-multiple {
            .p-autocomplete-multiple-container {
                padding: $inputPadding / 2 $inputPadding;

                &:not(.p-disabled):hover {
                    border-color: $inputHoverBorderColor;
                }

                &:not(.p-disabled).p-focus {
                    @include focused-input();
                }

                .p-autocomplete-input-token {
                    margin: 0;
                    padding: $inputPadding / 2 0;

                    input {
                        font-family: $fontFamily;
                        font-size: $fontSize;
                        padding: 0;
                        margin: 0;
                    }
                }

                .p-autocomplete-token {
                    font-size: $fontSize;
                    padding: $inputPadding / 2 $inputPadding;
                    margin: $chipsItemMargin;
                    background: $highlightBgColor;
                    color: $highlightColorText;
                }
            }
        }
    }

    .p-autocomplete-panel {
        padding: 0;
        border: $inputOverlayBorder;
        background-color: $inputListBgColor;
        @include shadow($inputOverlayShadow);

        .p-autocomplete-items {
            padding: $inputListPadding;

            .p-autocomplete-list-item {
                margin: $inputListItemMargin;
                border-bottom: solid thin #ddd;
                border: $inputListItemBorder;
                color: $inputListItemTextColor;
                background-color: $inputListItemBgColor;
                @include border-radius(0);

                &.p-highlight {
                    color: $inputListItemHighlightTextColor;
                    background-color: $inputListItemHighlightBgColor;
                }
            }

            .p-autocomplete-group {
                padding: $inputListItemPadding;
                background-color: $inputListItemDividerColor;
                color: $textColor;
            }
        }
    }

    .p-fluid {
        .p-autocomplete {
            .p-autocomplete-dropdown.p-button {
                width: $buttonIconOnlyWidth;
            }

            &.p-autocomplete-multiple.p-autocomplete-dd {
                .p-autocomplete-multiple-container {
                    border-right: 0 none;
                    width: calc(100% - #{$buttonIconOnlyWidth});
                }
            }

            &.p-autocomplete-dd {
                .p-inputtext {
                    border-right: 0 none;
                    width: calc(100% - #{$buttonIconOnlyWidth});
                }
            }
        }
    }

    .p-chips {
        > ul.p-inputtext {
            padding: $inputPadding / 2 $inputPadding;
            display: inline-block;

            &:not(.p-disabled):hover {
                border-color: $inputHoverBorderColor;
            }

            &:not(.p-disabled).p-focus {
                @include focused-input();
            }

            .p-chips-input-token {
                padding: $inputPadding / 2 0;

                input {
                    font-family: $fontFamily;
                    font-size: $fontSize;
                    padding: 0;
                    margin: 0;

                    &:hover {
                        border: 0 none;
                    }

                    &:focus {
                        border: 0 none;
                    }
                }
            }

            .p-chips-token {
                font-size: $fontSize;
                padding: $inputPadding / 2 $inputPadding;
                margin: $chipsItemMargin;
                background: $highlightBgColor;
                color: $highlightColorText;
            }
        }
    }

    .p-dropdown {
        background: $inputBgColor;
        border: $inputBorder;
        @include transition($inputTransition);

        &:not(.p-disabled):hover {
            border-color: $inputHoverBorderColor;
        }

        &:not(.p-disabled):focus {
            @include focused-input();
        }

        .p-dropdown-label {
            padding-right: 2em;
        }

        .p-dropdown-trigger {
            background-color: $inputBgColor;
            width: 2em;
            line-height: 2em;
            text-align: center;
            padding: 0;
            color: $inputDropdownIconColor;

            .p-dropdown-trigger-icon {
                @include icon_override("\e902");
            }
        }

        .p-dropdown-clear-icon {
            color: $inputDropdownIconColor;
        }

        &:not(.p-disabled).p-focus {
            @include focused-input();
        }
    }

    .p-dropdown-panel {
        padding: 0;
        border: $inputOverlayBorder;
        background-color: $inputListBgColor;
        @include shadow($inputOverlayShadow);

        .p-dropdown-filter-container {
            padding: $inputListHeaderPaddingTop $inputListHeaderPaddingRight $inputListHeaderPaddingBottom $inputListHeaderPaddingLeft;
            border-bottom: $inputListHeaderBorder;
            color: $inputListHeaderTextColor;
            background-color: $inputListHeaderBgColor;
            margin: $inputListHeaderMargin;

            .p-dropdown-filter {
                width: 100%;
                padding-right: 2em;
            }

            .p-dropdown-filter-icon {
                top: 50%;
                margin-top: -.5em;
                right: $inputListHeaderPaddingRight + .5em;
                color: $inputListHeaderSearchIconColor;
            }
        }

        .p-dropdown-items {
            padding: $inputListPadding;

            .p-dropdown-item, .p-dropdown-item-group {
                margin: $inputListItemMargin;
                padding: $inputListItemPadding;
                border: $inputListItemBorder;
                color: $inputListItemTextColor;
                background-color: $inputListItemBgColor;
                @include border-radius(0);

                &.p-highlight {
                    background-color: var(--brand-secondary-color);
                }

                &:not(.p-highlight):not(.p-disabled):hover {
                    color: $inputListItemHoverTextColor;
                    background-color: $inputListItemHoverBgColor;
                }
            }
        }
    }

    .p-multiselect, .p-treeselect {
        background: $inputBgColor;
        border: $inputBorder;
        @include transition($inputTransition);
        width: 100%;

        &:not(.p-disabled):hover {
            border-color: $inputHoverBorderColor;
        }

        &:not(.p-disabled):focus {
            @include focused-input();
        }

        .p-multiselect-label, .p-treeselect-label {
            padding: $inputPadding;
            padding-right: 2em;
        }

        .p-multiselect-trigger, .p-treeselect-trigger {
            background-color: $inputBgColor;
            width: 2em;
            line-height: 2em;
            text-align: center;
            padding: 0;
            color: $inputDropdownIconColor;

            .p-multiselect-trigger-icon, .p-treeselect-trigger-icon {
                @include icon_override("\e902");
            }
        }
    }

    .p-multiselect-panel, .p-treeselect-panel {
        padding: 0;
        border: $inputOverlayBorder;
        background-color: $inputListBgColor;
        @include shadow($inputOverlayShadow);

        .p-multiselect-header, .p-treeselect-header {
            padding: $inputListHeaderPaddingTop $inputListHeaderPaddingRight $inputListHeaderPaddingBottom $inputListHeaderPaddingLeft;
            border-bottom: $inputListHeaderBorder;
            color: $inputListHeaderTextColor;
            background-color: $inputListHeaderBgColor;
            margin: $inputListHeaderMargin;

            .p-multiselect-filter-container {
                float: none;
                width: 70%;
                display: inline-block;
                vertical-align: middle;
                margin-left: 0;

                .p-inputtext {
                    padding: $inputPadding;
                    padding-right: 2em;
                }

                .p-multiselect-filter-icon {
                    color: $inputListHeaderSearchIconColor;
                    top: 50%;
                    margin-top: -.5em;
                    right: .5em;
                    left: auto;
                }
            }

            .p-checkbox {
                margin-right: $inlineSpacing;
                float: none;
                vertical-align: middle;
            }

            .p-multiselect-close, .p-treeselect-close {
                color: $inputListHeaderCloseIconColor;
                top: 50%;
                margin-top: -.5em;
                @include transition($inputListHeaderCloseIconTransition);

                &:hover {
                    color: $inputListHeaderCloseIconHoverColor;
                }

                &:focus {
                    outline: 0 none;
                    color: $inputListHeaderCloseIconHoverColor;
                }
            }
        }

        .p-multiselect-items, .p-treeselect-items {
            padding: $inputListPadding;

            .p-multiselect-item, .p-treeselect-item {
                margin: $inputListItemMargin;
                padding: $inputListItemPadding;
                border: $inputListItemBorder;
                color: $inputListItemTextColor;
                background-color: $inputListItemBgColor;
                @include border-radius(0);

                &.p-highlight {
                    color: $inputListItemHighlightTextColor;
                    background-color: $inputListItemHighlightBgColor;
                }

                &:not(.p-highlight):not(.p-disabled):hover {
                    color: $inputListItemHoverTextColor;
                    background-color: $inputListItemHoverBgColor;
                }

                .p-checkbox {
                    position: static;
                    display: inline-block;
                    vertical-align: middle;
                    margin: 0 $inlineSpacing 0 0;
                }

                label {
                    display: inline-block;
                    vertical-align: middle;
                }
            }
        }
    }

    .p-listbox {
        padding: 0;
        min-width: $inputListMinWidth;
        background: $inputListItemBgColor;
        border: $inputBorder;

        .p-checkbox {
            margin: 0 $inlineSpacing 0 0;
        }

        .p-listbox-header {
            padding: $inputListHeaderPaddingTop $inputListHeaderPaddingRight $inputListHeaderPaddingBottom $inputListHeaderPaddingLeft;
            border-bottom: $inputListHeaderBorder;
            color: $inputListHeaderTextColor;
            background-color: $inputListHeaderBgColor;
            margin: $inputListHeaderMargin;

            .p-listbox-filter-container {
                width: calc(100% - (#{$inputListHeaderPaddingLeft} + #{$inputListHeaderPaddingLeft} + #{$inlineSpacing}));

                input {
                    padding: $inputPadding;
                    padding-right: 2em;
                }

                .p-listbox-filter-icon {
                    top: 50%;
                    left: auto;
                    margin-top: -.5em;
                    right: .5em;
                    color: $inputListHeaderSearchIconColor;
                }
            }
        }

        .p-listbox-list {
            .p-listbox-item {
                margin: $inputListItemMargin;
                padding: $inputListItemPadding;
                border: $inputListItemBorder;
                color: $inputListItemTextColor;
                background-color: $inputListItemBgColor;
                @include border-radius(0);

                &.p-highlight {
                    color: $inputListItemHighlightTextColor;
                    background-color: $inputListItemHighlightBgColor;
                }

                .p-checkbox {
                    position: static;
                    display: inline-block;
                    vertical-align: middle;
                    margin: 0 $inlineSpacing 0 0;
                }

                label {
                    display: inline-block;
                    vertical-align: middle;
                }
            }
        }


        &:not(.p-disabled) {
            .p-listbox-item {
                &:not(.p-highlight):not(.p-disabled):hover {
                    color: $inputListItemHoverTextColor;
                    background-color: $inputListItemHoverBgColor;
                }
            }
        }

        &.p-disabled {
            .p-checkbox-box {
                &:not(.p-disabled):not(.ui-state-active):hover {
                    border: $inputBorder;
                }
            }
        }

        .p-listbox-footer {
            padding: $inputListHeaderPaddingTop $inputListHeaderPaddingRight $inputListHeaderPaddingBottom $inputListHeaderPaddingLeft;
            border-top: $inputListHeaderBorder;
            color: $inputListHeaderTextColor;
            background-color: $inputListHeaderBgColor;
        }
    }

    .p-editor-container {
        .p-editor-toolbar {
            background-color: $panelHeaderBgColor;
        }

        .ql-picker.ql-expanded {
            .ql-picker-label {
                color: $panelHeaderTextColor;
            }
        }

        .ql-stroke {
            stroke: $panelHeaderTextColor;
        }

        .ql-picker-label {
            color: $panelHeaderTextColor;
        }

        .ql-snow.ql-toolbar button:hover,
        .ql-snow .ql-toolbar button:hover,
        .ql-snow.ql-toolbar button.ql-active,
        .ql-snow .ql-toolbar button.ql-active,
        .ql-snow.ql-toolbar .ql-picker-label:hover,
        .ql-snow .ql-toolbar .ql-picker-label:hover,
        .ql-snow.ql-toolbar .ql-picker-label.ql-active,
        .ql-snow .ql-toolbar .ql-picker-label.ql-active,
        .ql-snow.ql-toolbar .ql-picker-item:hover,
        .ql-snow .ql-toolbar .ql-picker-item:hover,
        .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
        .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
            color: $panelHeaderIconHoverColor;
        }

        .ql-snow.ql-toolbar button:hover .ql-stroke,
        .ql-snow .ql-toolbar button:hover .ql-stroke,
        .ql-snow.ql-toolbar button.ql-active .ql-stroke,
        .ql-snow .ql-toolbar button.ql-active .ql-stroke,
        .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
        .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
        .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
        .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
        .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
        .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
        .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
        .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
        .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
        .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
        .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
        .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
        .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
        .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
        .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
        .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
        .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
        .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
        .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
        .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
            stroke: $panelHeaderIconHoverColor;
        }
    }

    .p-rating {
        a {
            text-align: center;
            display: inline-block;
            color: $ratingStarIconColor;
            @include transition($ratingTransition);

            &.p-rating-cancel {
                color: $ratingCancelIconColor;
            }

            .p-rating-icon {
                font-size: $ratingIconFontSize;
            }
        }

        &:not(.p-disabled) {
            a {
                &:hover {
                    color: $ratingStarIconHoverColor;
                }

                &.p-rating-cancel {
                    &:hover {
                        color: $ratingCancelHoverIconColor;
                    }
                }
            }
        }
    }

    .p-spinner {
        .p-spinner-input {
            padding-right: $spinnerButtonWidth + $inputPadding;
        }

        .p-spinner-button {
            width: $spinnerButtonWidth;

            .p-spinner-button-icon {
                position: absolute;
                top: 50%;
                left: 50%;
                margin-top: -.5em;
                margin-left: -.5em;
                width: 1em;
            }
        }
    }

    .p-fluid {
        .p-spinner {
            .p-spinner-input {
                padding-right: $spinnerButtonWidth + $inputPadding;
            }

            .p-spinner-button {
                width: $spinnerButtonWidth;

                .p-spinner-button-icon {
                    left: 50%;
                }
            }
        }
    }

    .p-slider {
        background-color: $sliderBgColor;
        border: $sliderBorder;

        &.p-slider-horizontal {
            height: $sliderHeight;

            .p-slider-handle {
                top: 50%;
                margin-top: -1 * $sliderHandleHeight / 2;
            }
        }

        &.p-slider-vertical {
            width: $sliderWidth;

            .p-slider-handle {
                left: 50%;
                margin-left: -1 * $sliderHandleWidth / 2;
            }
        }

        .p-slider-handle {
            height: $sliderHandleHeight;
            width: $sliderHandleWidth;
            background-color: $sliderHandleBgColor;
            border: $sliderHandleBorder;
            @include border-radius($sliderHandleBorderRadius);
            @include transition($sliderHandleTransition);
        }

        .p-slider-range {
            background-color: $sliderRangeBgColor;
        }

        &:not(.p-disabled) {
            .p-slider-handle{
                &:hover {
                    background-color: $sliderHandleHoverBgColor;
                    border: $sliderHandleHoverBorder;
                }
                &:focus {
                    border: $sliderHandleFocusBorder;
                    background-color: $sliderHandleFocusBgColor;
                    outline: 0 none;
                }
            }
        }
    }

    .p-datepicker {
        padding: $calendarPadding;
        min-width: $calendarWidth;
        background-color: $inputContentPanelBgColor;
        border: $inputBorder;

        &:not(.p-datepicker-inline) {
            border: $inputOverlayBorder;
            @include shadow($inputOverlayShadow);
        }

        &:not(.p-disabled) {
            .p-datepicker-header {
                .p-datepicker-prev,
                .p-datepicker-next {
                    &:hover {
                        color: $calendarNavIconHoverColor;
                    }

                    &:focus {
                        outline: 0 none;
                        color: $calendarNavIconHoverColor;
                    }
                }
            }

            table {
                td {
                    a:not(.ui-state-active):not(.p-highlight):hover {
                        background-color: $calendarCellDateHoverBgColor;
                    }
                }
            }

            .p-monthpicker {
                a.p-monthpicker-month:not(.ui-state-active):hover {
                    background-color: $calendarCellDateHoverBgColor;
                }
            }
        }

        .p-datepicker-header {
            padding: $inputListHeaderPaddingTop $inputListHeaderPaddingRight $inputListHeaderPaddingBottom $inputListHeaderPaddingLeft;
            background-color: $inputListHeaderBgColor;
            color: $inputListHeaderTextColor;
            @include border-radius(0);

            .p-datepicker-prev,
            .p-datepicker-next {
                cursor: pointer;
                top: 0;
                color: $calendarNavIconColor;
                @include transition($calendarNavIconTransition);
            }

            .p-datepicker-next {
                span {
                    @include icon_override("\e901");
                }
            }

            .p-datepicker-prev {
                span {
                    @include icon_override("\e900");
                }
            }

            .p-datepicker-title {
                margin: 0;
                padding: 0;
                line-height: 1;

                select {
                    margin-top: -.35em;
                    margin-bottom: 0;
                }
            }
        }

        table {
            font-size: $fontSize;
            margin: $calendarTableMargin;

            th {
                //padding: $calendarHeaderCellPadding;
            }

            td {
                padding: $calendarCellPadding;
                > a, > span {
                    display: block;
                    text-align: center;
                    color: $textColor;
                    border: $calendarCellDateBorder;
                    padding: $calendarCellDatePadding;
                    @include border-radius($calendarCellDateBorderRadius);
                }

                > a {
                    cursor: pointer;

                    &:hover {
                        border: $calendarCellDateHoverBorder;
                    }

                    &.ui-state-active {
                        color: $calendarCellDateSelectedTextColor;
                        background-color: $calendarCellDateSelectedBgColor;
                        border: $calendarCellDateSelectedBorder;
                    }

                    &.p-highlight {
                        color: $calendarCellDateTodayTextColor;
                        background-color: $calendarCellDateTodayBgColor;
                        border: $calendarCellDateTodayBorder;
                        font-weight: 600;
                    }
                }

                &.p-datepicker-today {
                    a {
                        background-color: $calendarCellDateTodayBgColor;
                        color: $calendarCellDateTodayTextColor;
                    }
                }
            }
        }

        .p-datepicker-buttonbar {
            //border-top: $calendarButtonBarDivider;
        }

        .p-timepicker {
            border: 0 none;
            border-top: $calendarTimePickerDivider;
            padding: $calendarTimePickerPadding;

            a {
                color:$calendarTimePickerIconColor;
                font-size: $calendarTimePickerIconFontSize;

                &:hover {
                    color: $calendarTimePickerIconHoverColor;
                }
            }

            span {
                font-size: $calendarTimePickerTimeFontSize;
            }
        }

        .p-monthpicker {
            .p-monthpicker-month {
                color: $textColor;

                &.ui-state-active {
                    color: $calendarCellDateSelectedTextColor;
                    background-color: $calendarCellDateSelectedBgColor;
                }
            }
        }

        &.p-datepicker-timeonly {
            padding: 0;

            .p-timepicker {
                border-top: 0 none;
            }
        }

        &.p-datepicker-multiple-month {
            .p-datepicker-group {
               // border-right: $calendarMultipleMonthDivider;
                padding-right: $calendarPadding;
                padding-left: $calendarPadding;
                padding-top: 0;
                padding-bottom: 0;

                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                    border-right: 0 none;
                }
            }
        }
    }

    .p-calendar.p-calendar-w-btn {
        .p-inputtext {
            @include border-radius-right(0);
            border-right: 0 none;
            padding-right: 0;

            &:enabled:hover:not(.p-state-error),
            &:enabled:focus:not(.p-state-error) {
                border-right: 0 none;
            }
        }

        .p-datepicker-trigger.p-button {
            position: absolute;
            width: $buttonIconOnlyWidth;
            @include border-radius-left(0);
        }
    }

    .p-fileupload {
        .p-fileupload-buttonbar {
            background-color: $panelHeaderBgColor;
            padding: $panelHeaderPadding;
            border: $panelHeaderBorder;
            color: $panelHeaderTextColor;
            border-bottom: 0 none;

            .p-button {
                margin-right: 8px;
            }
        }

        .p-fileupload-content {
            background-color: $panelContentBgColor;
            padding: $panelContentPadding;
            border: $panelContentBorder;
            color: $panelContentTextColor;
        }

        .p-progressbar {
            top: 0;
        }
    }

    .p-fileupload-choose {
        &:not(.p-disabled):hover {
            background-color: $buttonHoverBgColor;
            color: $buttonHoverTextColor;
            border-color: $buttonHoverBorderColor;
        }

        &:not(.p-disabled):active {
            background-color: $buttonActiveBgColor;
            color: $buttonActiveTextColor;
            border-color: $buttonActiveBorderColor;
        }

        &.p-focus {
            outline: $buttonFocusOutline;
            outline-offset: $buttonFocusOutlineOffset;
        }
    }

    .p-password-panel {
        padding: $inputContentPanelPadding;
        background-color: $inputContentPanelBgColor;
        color: $inputContentPanelTextColor;
        border: $inputOverlayBorder;
        @include shadow($inputOverlayShadow);
    }

    .p-inputgroup {
        .p-inputgroup-addon {
            border-color: $inputGroupBorderColor;
            background-color: $inputGroupBgColor;
            color: $inputGroupTextColor;
            padding: $inputPadding;
            min-width: 2em;

            &:first-child {
                @include border-radius-left($borderRadius);
            }

            &:last-child {
                @include border-radius-right($borderRadius);
            }

            &.p-inputgroup-addon-checkbox {
                position: relative;

                .p-checkbox {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    margin-left: -1 * $checkboxWidth / 2;
                    margin-top: -1 * $checkboxHeight / 2;
                }
            }

            &.p-inputgroup-addon-radiobutton {
                position: relative;

                .p-radiobutton {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    margin-left: -1 * $radiobuttonWidth / 2;
                    margin-top: -1 * $radiobuttonHeight / 2;
                }
            }
        }

        .p-button {
            &:first-child {
              @include border-radius-left($borderRadius);
            }

            &:last-child {
              @include border-radius-right($borderRadius);
            }
        }
    }

    .p-fluid {
        .p-inputgroup {
            .p-button {
                width: auto;

                &.p-button-icon-only {
                    width: $buttonIconOnlyWidth;
                }
            }
        }
    }

    .p-calendar .p-datepicker {
        min-width: auto;
    }

    ::-webkit-input-placeholder {color:$inputPlaceholderTextColor}
    :-moz-placeholder           {color:$inputPlaceholderTextColor}
    ::-moz-placeholder          {color:$inputPlaceholderTextColor}
    :-ms-input-placeholder      {color:$inputPlaceholderTextColor}

    /* Validations */
    .p-inputtext.ng-dirty.ng-invalid,
    p-dropdown.ng-dirty.ng-invalid > .p-dropdown,
    p-autocomplete.ng-dirty.ng-invalid > .p-autocomplete > .p-inputtext,
    p-calendar.ng-dirty.ng-invalid > .p-calendar > .p-inputtext,
    p-chips.ng-dirty.ng-invalid > .p-inputtext,
    p-inputmask.ng-dirty.ng-invalid > .p-inputtext,
    p-checkbox.ng-dirty.ng-invalid .p-checkbox-box,
    p-radiobutton.ng-dirty.ng-invalid .p-radiobutton-box,
    p-inputswitch.ng-dirty.ng-invalid .p-inputswitch,
    p-listbox.ng-dirty.ng-invalid .p-inputtext,
    p-multiselect.ng-dirty.ng-invalid > .p-multiselect,
    p-spinner.ng-dirty.ng-invalid > .p-inputtext,
    p-selectbutton.ng-dirty.ng-invalid .p-button,
    p-togglebutton.ng-dirty.ng-invalid .p-button {
        border-color: $inputErrorBorderColor;
    }
}
