$input-padding: 1rem 0.5rem;

$radio-button-checked: #aaaaaa;

body {
  form {
    width: 100%;
  }

  .p-autocomplete-dd {
    .p-autocomplete-input {
      border-right: none;
    }
    .p-autocomplete-dropdown {
      border-left: none;
    }
  }

  .p-multiselect,
  .p-treeselect {
    background: none;
    border-radius: 6px;
    .p-multiselect-label,
    .p-treeselect-label {
      padding: $input-padding;
    }
    .p-multiselect-trigger,
    .p-treeselect-trigger {
      background: none;
      padding-right: 0.75rem;
      border-radius: 6px;
      color: black;
    }
  }

  .p-multiselect-panel,
  .p-treeselect-panel {
    .p-multiselect-header,
    .p-treeselect-header {
      .p-multiselect-close,
      .p-treeselect-close {
        margin: 0 0 0 0.5rem;
      }
    }

    .p-multiselect-item-group,
    .p-treeselect-item-group {
      padding: 0 10px;
      margin: 0.5rem 0;
      font-weight: bold;

      &:not(:first-child) {
        border-top: 1px solid var(--primary-color-300);
        padding-top: 0.5rem;
      }
    }

    .p-multiselect-items-wrapper {
      padding: 0.5rem;

      .p-multiselect-items {
        .p-multiselect-item,
        span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 20rem;
          min-width: 10rem;
        }
      }
    }
  }

  .p-danger {
    color: var(--btn-danger-color);
  }

  .ng-invalid.ng-touched {
    &:not(form):not(div) {
      &:not(p-dropdown) {
        border: 1px solid var(--btn-danger-color);
        &:focus-visible {
          outline: var(--btn-danger-color) 2px auto;
        }
      }
      .p-dropdown {
        border: 1px solid var(--btn-danger-color);
      }
    }
  }
  .ng-invalid.ng-touched ~ .p-inputgroup-addon {
    background-color: red;
    border: 1px solid var(--btn-danger-color);
  }

  label {
    display: inline-block;
    margin-bottom: 0.5rem;
    color: #888;
  }

  label.form-control-label {
    display: block;
    margin: 1rem 0 0.5rem;
  }

  .form-control-feedback {
    position: absolute;
    z-index: 1;
  }

  .p-calendar {
    width: 100%;
    .pi-calendar {
      color: var(--brand-primary-color);
    }
  }

  .p-autocomplete {
    width: 100%;
  }

  .p-inputnumber {
    width: 100%;
  }

  .p-multiselect {
    width: 100%;
  }

  .no-border .p-dropdown {
    border: none;
  }

  .small .p-dropdown {
    width: fit-content;
    .p-dropdown-label {
      padding: 0.5rem 0.75rem;
    }
  }

  .p-highlight .p-dropdown-label {
    color: var(--brand-primary-color);
  }

  .p-dropdown {
    font-weight: bold;
    background-color: white;
    border-radius: 6px;
    width: 100%;
    .p-dropdown-trigger {
      color: black;
      background-color: white;
      border-radius: 0 6px 6px 0;
      padding-right: 0.75rem;
    }
    .p-dropdown-label {
      border: none;
      padding-left: 0.75rem;
      padding-right: 0;
    }
    .p-dropdown-panel {
      border: 1px solid var(--primary-color-300);
      max-width: 150%;
    }
  }

  .p-dropdown-panel {
    .p-dropdown-items-wrapper {
      .p-dropdown-items {
        .p-dropdown-empty-message {
          padding: 0.5rem;
        }
      }
    }
  }

  .p-inputtext {
    font-weight: 500;
    font-size: 15px;
    color: black;
    background-color: white;
    width: 100%;
    max-width: 100%;
    padding: $input-padding;
    border-radius: 6px;
    line-height: 15px;

    &.p-inputtextarea {
      min-height: 3.5rem;
    }

    &.p-autocomplete-input {
      padding: $input-padding;
    }
  }

  .p-radiobutton {
    .p-radiobutton-box {
      &.p-highlight {
        .p-radiobutton-icon {
          background: $radio-button-checked;
        }
      }
    }
  }

  .p-checkbox .p-checkbox-box {
    background-color: var(--primary-color-100);
    border-color: var(--primary-color-400);
    .p-checkbox-icon {
      color: black;
    }
    &:hover {
      background-color: var(--primary-color-300);
      border-color: var(--primary-color-500);
    }
  }

  .p-autocomplete-panel .p-autocomplete-items {
    min-width: 500px;
  }

  .p-autocomplete-item {
    &.p-highlight {
      background-color: var(--primary-color-200);
    }
  }

  .p-autocomplete-panel .p-autocomplete-items .autocomplete-header-row {
    display: none;
  }

  .p-autocomplete-panel
    .p-autocomplete-item:first-child
    .autocomplete-header-row {
    display: block;
    background: $brand-secondary-color;
    font-weight: bold;
    padding-top: 4px;
  }

  .p-autocomplete-panel .p-autocomplete-items .autocomplete-row > div {
    display: block;
    text-align: left;
    padding: 2px 5px;
    word-break: break-word;
    float: left;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .p-inputswitch {
    .p-inputswitch-slider {
      &:before {
        top: auto;
      }
    }
    &.p-inputswitch-checked .p-inputswitch-slider {
      &:before {
        background-color: var(--brand-primary-color);
      }
    }
  }
  .p-inputgroup {
    .p-component,
    .p-inputgroup-addon,
    .p-button {
      transition: 0.2s ease-in-out;
    }
    &:hover,
    &:active {
      .p-inputgroup-addon,
      .p-button,
      .p-component {
        border-color: #a6a6a6;
      }
    }
    &:focus,
    &:focus-within {
      .p-inputgroup-addon,
      .p-button,
      .p-component,
      .p-inputtext:enabled {
        border-color: var(--primary-color-500);
        box-shadow: none;
      }
    }

    :not(button).p-inputgroup-addon {
      background-color: white;
      border: 1px solid #dadada;
    }

    button.p-inputgroup-addon {
      padding: $input-padding;
    }

    label.p-inputgroup-addon {
      padding: $input-padding;
      margin: 0;
      border-right: none;
      padding-bottom: 0.8rem;
    }

    app-project-select {
      &:not(:first-child) {
        app-dropdown-select {
          .dropdown-select-dropdown-toggle {
            border-left: none;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }
      }
      &:not(:last-child) {
        app-dropdown-select {
          .dropdown-select-dropdown-toggle {
            border-right: none;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
      }
    }

    app-contact-select,
    app-product-search,
    app-project-subcontractor-search {
      width: 100%;
      &:not(:first-child) {
        .p-inputwrapper:first-child {
          .p-autocomplete {
            .p-inputtext {
              border-left: none;
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
            }
          }
        }
      }
      &:not(:last-child) {
        .p-inputwrapper:first-child {
          .p-autocomplete {
            .p-inputtext {
              border-right: none;
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
            }
          }
        }
      }
    }

    ::first-child {
      border-right: none;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }
    ::last-child {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      border-left: none;
    }

    .p-inputwrapper {
      .p-autocomplete {
        width: 100%;
      }

      .p-dropdown {
        width: 100%;
      }

      .p-autocomplete {
        .p-autocomplete-input {
          border-radius: 0;
        }
      }

      &:not(:last-child) {
        .p-component:not(.p-dropdown-panel) {
          border-right: none;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
        }
      }

      &:not(:first-child) {
        .p-component:not(.p-dropdown-panel) {
          border-left: none;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }

      &:first-child {
        .p-dropdown,
        .p-inputtext,
        .p-autocomplete {
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
        }
      }
      &:last-child {
        .p-dropdown,
        .p-inputtext,
        .p-autocomplete,
        ::not(.p-calendar) {
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
        }
      }
    }
    .p-component:not(.p-dropdown-panel) {
      &:first-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      &:last-child {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
      &:not(:first-child) {
        border-left: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
      &:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
    .p-inputgroup-addon {
      padding: 0;
      min-width: 3rem;
      &:first-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      &:last-child {
        border-left: none;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
  }

  .p-autocomplete-loader {
    right: 0.5rem;
  }

  input[type='number'] {
    appearance: auto;
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      appearance: none;
      margin: 0;
      -webkit-appearance: none;
    }
  }

  .p-inline-message {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: start;
    &.p-inline-message-error {
      color: var(--btn-danger-color);
    }
    .p-inline-message-icon {
      padding: 0 0.5rem;
    }
    div {
      padding: 0.5rem;
    }
  }

  .p-datepicker table td > span {
    &.p-highlight {
      background-color: var(--brand-primary-color);
      color: white;
    }
  }
  .p-datepicker-today > span {
    background-color: var(--brand-secondary-color);
    color: white;
  }

  .p-dropdown-item {
    white-space: break-spaces;
  }

  .p-editor-container
    .p-editor-toolbar.ql-snow
    .ql-color-picker.ql-expanded
    .ql-picker-options
    .ql-picker-item {
    width: 1rem;
    height: 1rem;
  }

  .p-selectbutton {
    .p-element {
      &.p-button {
        background-color: $white;

        &:not(.p-disabled):not(.p-highlight):not(.ui-state-active):hover {
          background-color: var(--primary-color-100);
        }

        &.p-highlight {
          background-color: $primary-btn-color;
          color: $white;

          &:hover {
            background-color: $primary-btn-color-dark;
            color: $white;
          }
        }
      }
    }
  }
}
