//shades
$primaryColor100: var(--primary-color-100);
$primaryColor200: var(--primary-color-200);
$primaryColor300: var(--primary-color-300);
$primaryColor400: var(--primary-color-400);
$primaryColor500: var(--primary-color-500);
$primaryColor600: var(--primary-color-600);

//main color
$primaryColor: $primaryColor500;

//highlight
$highlightBgColor: #cfe4de;
$highlightBgColorDark: #bfdbd3;
$highlightColorText: #385048;

$primaryTextColor: #708895;
$inputGroupBgColor: #dbdbdb;

// App main color
$brand-primary-color: var(--brand-primary-color);
$brand-secondary-color: var(--brand-secondary-color);
$body-background-color: #f6f6f6;

// general colors
$link-color: var(--link-color);

$primary-btn-color: var(--btn-primary-color);
$primary-btn-color-dark: var(--btn-primary-color-dark);

$success-btn-color: var(--btn-success-color);

$btn-info-color: var(--btn-info-color);

$btn-warning-color: var(--btn-warning-color);

$btn-danger-color: var(--btn-danger-color);

// menu specific
$top-menu-box-shadow: inset 0 5px 5px -4px var(--top-menu-box-shadow);
$top-menu-color: var(--top-menu-color);
$top-menu-hover-color: var(--top-menu-hover-color);
$top-menu-background-color: var(--top-menu-background-color);

// Various colors
$text-color: #676a6c; // Body text
$light-gray: #d1dade; // Default label, badget
$btn-gray: #909090;
$almost-black: #231f20;
$white: white;

$progress-loading: #009dff;
$progress-success: #13db59;
$progress-error: #ff0000;

// Typography
$btn-border-radius: 3px;
$element-padding: 0.85em;

// Responsiveness
$break-mobile: 320px;
$break-md: 641px;
$break-tablet: 768px;
$break-laptop: 1025px;
$break-xl: 1441px;
$break-desktop: 1800px;
$break-desktop-lg: 1921px;

// Shadows
$shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.3);
$shadow-small: 0px 1px 5px 0px rgba(0, 0, 0, 0.3);
$shadow-large: 0px 2px 20px 0px rgba(0, 0, 0, 0.3);
$shadow-center: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
$shadow-center-alt: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
$shadow-center-small: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
$shadow-center-large: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);

$menu-shadow: inset 0 5px 5px -4px rgba(0, 0, 0, 0.2);
$dropdown-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.37);
$dropdown-shadow-small: 0px 2px 1px 0px rgba(0, 0, 0, 0.3);

$background-gray: #f2f2f2;
$background-gray-darker: #e2e2e2;
$background-gray-darkest: #d1d1d1;
$background-teal: #008080;

$medium-gray: #d8d6d6; //
$table-head-color: #5d5d5d;

$system-type-separator-color: #eee;
$line-separator-color: #eee;

// Form
$form-error-message-color: #dd3636;
$input-error-border-color: #e7a3a3;
$input-disabled-text-color: #999999;

@mixin box-shadow($shadow-properties) {
  -webkit-box-shadow: $shadow-properties;
  -moz-box-shadow: $shadow-properties;
  box-shadow: $shadow-properties;
}
