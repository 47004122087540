// Search
.layout-topbar .layout-topbar-search-item input {
  border: none;
  border-bottom: solid thin #ddd;
  &:focus {
    border-bottom: solid thin var(--brand-primary-color);
    outline-color: transparent;
  }
}
html body .layout-topbar .layout-topbar-search-item button.p-button {
  background-color: transparent !important;
  border: none;
}

.sub-menu {
  h4 {
    margin: 0;
    // margin-left: .6em;
    padding: 0.3em 0 0.5em;
    margin-bottom: 0.4em;
    font-size: 1.1em;
    // border-bottom: 1px solid $background-gray-darkest;
  }
  a {
    font-size: 0.9em;
  }
  li {
    position: relative;
    padding: 0.4em 0.6em;
    font-size: 1em;
    // width: 100%;
    transition: all 0.3s;
    border-bottom: 1px solid var(--top-menu-background-color);
    &:first-child {
      border-top: 1px solid var(--top-menu-background-color);
    }
    &:hover {
      cursor: pointer;
      z-index: 10;
    }
    &:hover,
    &.active {
      @include box-shadow($shadow-center-small);
      background-color: var(--top-menu-background-color);
      a {
        color: var(--top-menu-color);
      }
    }
  }
}

.enklakontoret {
  .sub-menu {
    li {
      border-bottom: 1px solid var(--primary-color-200);

      a {
        color: #6d5d60;
      }

      &:first-child {
        border-top: 1px solid var(--primary-color-200);
      }
      &:hover {
        cursor: pointer;
        z-index: 10;
      }
      &:hover,
      &.active {
        background-color: var(--brand-primary-color);
        box-shadow: none;

        a {
          color: var(--top-menu-color);
        }
      }
    }
  }
}

@media screen and (min-width: 897px) {
  .layout-wrapper.layout-horizontal
    .layout-menu-container
    .layout-menu
    > li
    > a {
    height: 39px;
    padding: 10px 10px;
  }

  .layout-wrapper a:not(.layoutSelector) .layout-submenu-toggler {
    display: none;
  }
  .layout-wrapper .ng-trigger-children {
    display: none;
  }

  .layout-wrapper.layout-horizontal {
    min-height: auto;

    .layout-menu-container {
      min-height: 39px;
    }

    .layout-topbar-dropdown ul {
      margin: 8px 0px 8px 0px;
      padding: 0px;

      li {
        display: block;
        float: none;
        padding: 0px;
        width: 100%;
        box-sizing: border-box;
        a {
          padding: 3px 0px 3px 10px;
        }
      }
    }
  }

  .layout-wrapper.layout-horizontal
    .layout-menu-container
    .p-scrollpanel
    .p-scrollpanel-wrapper
    .p-scrollpanel-content {
    width: auto;
  }
}

.layout-wrapper.layout-menu-light
  .layout-menu-container
  .layout-menu
  li
  a:hover,
.layout-wrapper.layout-menu-light
  .layout-menu-container
  .layout-menu
  > li.active-menuitem
  > a {
  background-color: transparent !important;
}

.layout-wrapper.layout-menu-light .layout-menu-container .layout-menu li a,
.layout-wrapper.layout-menu-light
  .layout-menu-container
  .layout-menu
  li.active-menuitem
  > a {
  color: var(--top-menu-color);
  &:hover {
    color: var(--top-menu-hover-color);
  }

  &.create {
    padding-left: 0px;
  }
  &.hasCreate {
    padding-right: 0px;
  }
}

.layout-wrapper .layout-menu-container .layout-menu li a .layout-menuitem-text {
  font-size: 13px;
  font-weight: var(--top-menu-font-weight);
  text-transform: var(--top-menu-text-transform);
}
.layout-wrapper.layout-menu-light
  .layout-menu-container
  .layout-menu
  > li
  > a.active-menuitem-routerlink
  .layout-menuitem-text,
.layout-wrapper.layout-menu-light
  .layout-menu-container
  .layout-menu
  > li.active-menuitem
  > a:hover,
.layout-wrapper.layout-menu-light
  .layout-menu-container
  .layout-menu
  > li.active-menuitem
  > a
  .layout-menuitem-text {
  color: var(--top-menu-hover-color);
}
.layout-wrapper.layout-menu-light
  .layout-menu-container
  .layout-menu
  li
  a:hover,
.layout-wrapper.layout-menu-light
  .layout-menu-container
  .layout-menu
  > li.active-menuitem
  > a {
  background-color: var(--top-menu-background-active-color) !important;
}

.layout-topbar li > a {
  cursor: pointer !important;
}

.layout-wrapper .layout-topbar {
  height: auto !important;
  padding: 6px 1rem 1px;
  background-color: #fff !important;
}
.layout-topbar-usermenu-active {
  z-index: 999 !important;
}

.layout-wrapper .layout-topbar .layout-topbar-icons li {
  font-size: 19px;
  vertical-align: middle;
  i {
    color: #000;
    &:hover {
      color: var(--top-menu-background-color);
    }
  }
}

.layout-wrapper .layout-topbar .layout-topbar-logo img {
  height: auto;
  max-height: var(--top-menu-logo-max-height);
}

@media screen and (max-width: 40em) {
  .layout-wrapper .layout-topbar {
    height: auto;
  }
}

.layout-wrapper.layout-menu-light .layout-menu-container,
.layout-menu-button {
  background-color: $top-menu-background-color;
}

.layout-menu-button {
  color: var(--top-menu-color);
  &:hover,
  &:active {
    color: var(--top-menu-hover-color);
  }
}

.layout-wrapper .layout-topbar .layout-topbar-usermenu {
  top: 52px;
}

.layout-topbar-usermenu-active {
  .is-error {
    i {
      color: $btn-danger-color;
    }
  }
  .notification-holder {
    padding: 5px 0px 0px 0px;
  }
  .notification-icons {
    padding-left: 5px;
  }
  .notification-opener {
    &:hover {
      color: $brand-primary-color;
    }
    transition: color 0.5s;
  }
  .notification-icons {
    margin: 10px 0 5px;
  }
  table {
    font-size: 12px;
    width: 660px;
    @media (max-width: 800px) {
      width: 100%;
    }
    thead,
    tbody {
      display: block;
    }
    thead {
      width: calc(100% - 10px);
      display: table;
      table-layout: fixed;
    }
    tbody {
      height: 200px;
      width: 100%;
      overflow-y: scroll;
      overflow-x: hidden;
      tr {
        border-bottom: 1px solid $text-color;
      }
    }
    tr {
      display: table;
      width: 100%;
      table-layout: fixed;
    }
    th,
    td {
      padding: 5px;
      &:first-child {
        padding-left: 10px;
      }
    }
    th {
      text-align: left;
      font-weight: bold;
      .fa-check {
        color: $primary-btn-color;
      }
    }
    td {
      &.not-read {
        // color: $primary-btn-color;
        span {
          font-weight: bold;
        }
      }
    }
  }
}

.navbar-sub {
  display: none;
  z-index: 0;
  background-color: var(--brand-secondry-color);
  box-shadow: inset 0 5px 5px -4px rgba(0, 0, 0, 0.2);
  ul {
    list-style: none;
    margin: 0;
    padding-left: 20px;
    li {
      display: inline-block;
      padding-right: 0.3em;
      a {
        padding: 1em 0.875em;
        color: $almost-black;
        font-size: 0.78em;
        display: inherit;
        &:hover,
        &.active {
          background-color: #fff;
          color: #231f20;
          box-shadow: inset 0 5px 5px -4px rgba(0, 0, 0, 0.2);
        }
      }
      a:first-child {
        margin-left: -1em;
      }
    }
  }
}
