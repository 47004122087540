@mixin filled-button($colorParam) {
  color: white;
  background-color: $colorParam;
  border: none;
  &:hover {
    background-color: $colorParam;
    filter: grayscale(50%);
  }
  &:hover,
  &.active-button {
    color: white;
  }
  &:disabled {
    opacity: 0.8;
    &:hover {
      cursor: not-allowed;
    }
  }
}

@mixin colored-icon($colorParam) {
  color: $colorParam;
  &:hover,
  &.active-button {
    color: $colorParam;
  }
}

@mixin colored-inverted-button($colorParam) {
  color: $colorParam;
  border-color: $colorParam;
  background-color: white;
  &:hover {
    color: $colorParam;
    background-color: white;
    border-color: $colorParam;
    filter: grayscale(70%);
  }
  &:hover,
  &.active-button {
    background-color: white;
  }
}

html body p-splitButton {
  &.p-button-icon-danger {
    .p-button {
      @include colored-icon($btn-danger-color);
    }
  }

  &.p-button-icon-primary {
    .p-button {
      @include colored-icon($primary-btn-color);
    }
  }

  &.p-button-icon-success {
    .p-button {
      @include colored-icon($primary-btn-color);
    }
  }

  &.p-button-primary-hyperion {
    .p-button {
      @include filled-button($primary-btn-color);
    }
  }
  &.p-button-brand-primary {
    .p-button {
      @include filled-button($brand-primary-color);
    }
  }
  &.p-button-danger {
    .p-button {
      @include filled-button($btn-danger-color);
    }
  }
  &.p-button-warning {
    .p-button {
      @include filled-button($btn-warning-color);
    }
  }
  &.p-button-primary-inverted {
    .p-button {
      @include colored-inverted-button($primary-btn-color);
    }
  }
  &.p-button-danger-inverted {
    .p-button {
      @include colored-inverted-button($btn-danger-color);
    }
  }
}

html body .p-button {
  &.legacy-rounded-button {
    width: 2rem;
    height: 2rem;
    text-align: center;
    padding: 0.5rem;
    border-radius: 100%;
  }

  &.p-button-icon-danger {
    @include colored-icon($btn-danger-color);
  }

  &.p-button-icon-primary {
    @include colored-icon($primary-btn-color);
  }

  &.p-button-icon-success {
    @include colored-icon($primary-btn-color);
  }

  &.p-button-primary-hyperion {
    @include filled-button($primary-btn-color);
  }
  &.p-button-brand-primary {
    @include filled-button($brand-primary-color);
  }
  &.p-button-danger {
    @include filled-button($btn-danger-color);
  }
  &.p-button-warning {
    @include filled-button($btn-warning-color);
  }
  &.p-button-primary-inverted {
    @include colored-inverted-button($primary-btn-color);
  }
  &.p-button-danger-inverted {
    @include colored-inverted-button($btn-danger-color);
  }
}

body .p-button.ui-state-default.p-button-primary,
body .p-buttonset.p-button-primary > .p-button.ui-state-default {
  color: var(--primary-color-600);
  background-color: var(--primary-color-300);
  border: 1px solid var(--primary-color-400);
  transition: background-color 0.2s;
  &:hover {
    background-color: var(--primary-color-400);
    transition: background-color 0.2s;
  }
}

body .p-button.ui-state-default.p-button-secondary,
body .p-buttonset.p-button-secondary > .p-button.ui-state-default {
  color: var(--primary-color-600);
  background-color: var(--primary-color-100);
  border: 1px solid var(--primary-color-100);
  transition: background-color 0.2s;
  &:hover {
    background-color: var(--primary-color-200) !important;
    transition: background-color 0.2s;
  }
}

p-splitButton .p-splitbutton {
  display: flex;
  button {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &.p-button {
      width: 85%;
    }
    &.p-splitbutton-menubutton {
      width: 15% !important;
    }
  }
}

.btn-notification {
  position: relative;
  .badge {
    position: absolute;
    top: -10px;
    right: -10px;
    border-radius: 50%;
    background: #ff8a80;
    color: white;
    pointer-events: none;
    text-align: center;
    width: 22px;
    height: 22px;
    line-height: 22px;
    font-weight: 600;
    font-size: 12px;
    z-index: 2;
  }
}
