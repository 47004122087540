/*
	HYPERION _MIX
*/

@mixin fixLongText($widthParam) {
  vertical-align: bottom;
  display: inline-block;
  max-width: $widthParam;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

// Shadow
@mixin dropdown-shadow($shadowProp) {
  -webkit-box-shadow: $shadowProp;
  -moz-box-shadow: $shadowProp;
  box-shadow: $shadowProp;
}

@mixin inset-shadow($shadowProp) {
  -webkit-box-shadow: $shadowProp;
  -moz-box-shadow: $shadowProp;
  box-shadow: $shadowProp;
}

@mixin add-margin($margin-bottom) {
  margin-bottom: $margin-bottom;
}

@mixin add-background-color($color) {
  background-color: $color;
  color: white;
}

@mixin scrollbar($scrollbar-width) {
  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  &::-webkit-scrollbar {
    width: $scrollbar-width;
  }
}
